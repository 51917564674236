import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LipaLoan from '../../components/lipa-loan/LipaLoan';
import ApplyNewConnections from '../../components/maji_loan/ApplyNewConnections';
import majiLoan from '../../img/colored/view_bill.svg';
import LipaMaji from '../../img/colored/lipa_maji.svg';
import NewConnection from '../../img/colored/apply_new_connection.svg';
import './majiLoan.css';
import { useDispatch, useSelector } from 'react-redux';
import { getLoanee, reset } from '../../features/loanee/loaneeSlice';
import { formatMsToDate } from '../../utils';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function MajiLoan() {
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);
    const { loanee, isLoadingLoanee, isLoading, isErrorLoanee } = useSelector(state => state.loanee);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        dispatch(getLoanee({
            lnUsrId: user.usrId,
            wacWtrId: 1
        }))
        
        return () => {
            dispatch(reset());
        } 
    }, [])

    return (
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4 main-window">
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-1">
                <h4 class="page-intros">Maji Loan(s)</h4>
            </div>

                    <div className='row'>
                        <div class="col-12 col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="water-blue">Maji Loan(s)</h4>

                                    <Box sx={{ width: '100%' }}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="maji-loan-tabs">
                                            <Tabs value={value} onChange={handleChange} sx={{ justifyContent: 'space-evenly' }} aria-label="basic tabs example">
                                                <Tab 
                                                    label="Lipa Maji Loan" 
                                                    {...a11yProps(0)} 
                                                    style={{textTransform: 'none'}}
                                                    icon={<img src={LipaMaji} style={{ width:'35px', height:'25px'}}/>} 
                                                    iconPosition="start"
                                                />
                                                
                                                <Tab
                                                    label="New Connection Loan" 
                                                    {...a11yProps(1)} 
                                                    style={{textTransform: 'none'}}
                                                    icon={<img src={NewConnection} style={{ width:'35px', height:'25px'}} />}
                                                    iconPosition="start"
                                                />
                                            </Tabs>
                                        </Box>
                                        <TabPanel value={value} index={0}>
                                            <LipaLoan />     
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                            <ApplyNewConnections/>
                                        </TabPanel>
                                    </Box>

                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-5">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">My Requests</h5>
                                    <div class="row">
                                        {isLoading ? (
                                            <p>Loading...</p>
                                        ) : (
                                            
                                            <>
                                                { loanee.length ? (
                                                    loanee.map((loan) => (
                                                        <>
                                                            <div class="container-fluid mt1">
                                                                <div class="row for-readings pt1">

                                                                    <div class="col pb1">
                                                                    <div class="row">
                                                                    <div class="col">
                                                                        <div class="row">
                                                                        <div class="col-4 notf-img"><img src={majiLoan} alt="maji-loan" /></div>
                                                                        <div class="col-8 pull-right text-right r-date"> { 
                                                                            formatMsToDate(loan.lnCreatedDate)
                                                                        }</div>
                                                                        </div>
                                                                    </div>
                                                                    </div>

                                                                    <div class="row mt0">
                                                                    <div class="col-6 notf">
                                                                        
                                                                        <p>{loan.lnCode}</p>
                                                                        <p>Principal KES: {loan.lnPrincipalAmount}</p>
                                                                        <p>Intrest KES: {loan.lnInterest}</p>
                                                                        <p>
                                                                            <b>
                                                                            Total KES: {loan.lnAmount}
                                                                            </b>
                                                                            </p>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="row">
                                                                        <div class="col text-right">
                                                                            <span style={{color: '#b8b8b8;'}}><i class="icon ion-md-checkmark-circle"></i></span> 
                                                                            {loan.lnStatus}
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                </div>

                                                                <div class="card-footer" style={{padding:0}}></div>
                                                            </div>
                                                        
                                                        </>
                                                    ))
                                                ) : (<></>)}
                                                
                                            </>
                                        )}
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

        

           
           
        </main>
    );
}
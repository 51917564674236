// import './viewStatementDialog.css';
import {
    Card,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    DialogContentText,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Table
} from '@mui/material';
import {
    Article,
    Circle,
    Today,
    InsertInvitation,
    Download,
    Assignment,
    ArrowForwardIosSharp,
    AssignmentOutlined,
    Delete,
    Create
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { reset } from '../../features/view_bill/viewBillSlice';
import CloseIcon from '@mui/icons-material/Close';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}


function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontWeight: 'bold' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
const ViewStatementDialog = ({ open, handleOpenStatement, handleCloseStatement, viewStatement, isLoadingViewStatement }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        console.log("viewStatementdial", viewStatement);
    }, [reset, viewStatement])

    return (
        <>
            <Dialog
                open={open}
                onClose={handleCloseStatement}
                fullWidth={true}
                maxWidth="lg"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ borderRadius: 5 }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseStatement}>
                    My billing history
                </BootstrapDialogTitle>
                {!isLoadingViewStatement && (
                    <DialogContent dividers>
                        <Grid item xs={12}>
                            <TableContainer >
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Created</TableCell>
                                            <TableCell align="left">Particulars</TableCell>
                                            <TableCell align="left">Period</TableCell>
                                            <TableCell align="left">Debt</TableCell>
                                            <TableCell align="left">Credit</TableCell>
                                            <TableCell align="left">Balance</TableCell>
                                            <TableCell align="left">Details</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {viewStatement.map((statement) => (
                                            <TableRow
                                                key={statement.trnId}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {statement.trnCreatedBy}
                                                </TableCell>
                                                <TableCell align="left">{statement.trnParticulars}</TableCell>
                                                <TableCell align="left">{statement.periodName}</TableCell>
                                                <TableCell align="left">{statement.trnType}</TableCell>
                                                <TableCell align="left">{statement.cAccBal}</TableCell>
                                                <TableCell align="left">{statement.trnAmount}</TableCell>
                                                <TableCell align="left">{statement.invoDetails}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>


                    </DialogContent>
                )}
                {isLoadingViewStatement && (
                    <Skeleton count={10} width={1200} />
                )}
                <DialogActions>
                    <Button sx={{ textTransform: 'capitalize' }}
                        onClick={handleCloseStatement}
                        variant="contained"
                        disableElevation
                        color="inherit">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ViewStatementDialog
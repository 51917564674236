import axios from "axios"

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;
 
// postApplyNewConnection
const postApplyNewConnection = async (_formData) => {
  console.log("_formData",_formData.aorWtrId)
    const response = await axios.post(`${API_URL}/usr/postAccountOpeningRequest.action?wacWtrId=${_formData.aorWtrId}`,_formData)
  //   if (!response.data.success) {
  //     return Promise.reject(response.data.messages.mainMessage) 
  // }
    const result = response.data.jsonData;
    response.data.jsonData = [];
    return result;
  }


 

// getNewConnection
const getNewConnection = async ({wacWtrId,usrId}) => {
  console.log("wacWtrId",wacWtrId)
    const response = await axios.get(`${API_URL}/usr/fetchAccountOpeningRequest.action?wacWtrId=${wacWtrId}&usrId=${usrId}`)
    if (!response.data.success) {
      return Promise.reject(response.data.messages.mainMessage) 
  }
    const result = response.data.jsonData;
    response.data.jsonData = [];
    return result;
  }
  
// postConnectionDoc
const postConnectionDoc = async (formData) => {
    const response = await axios.post(`${API_URL}/usr/postDocuments.action`,formData)
    const result = response.data.jsonData;
    response.data.jsonData = [];
    return result;
  }

 
 
const applyNewConnectionService={
    postApplyNewConnection,
    getNewConnection,
    postConnectionDoc,
}
export default applyNewConnectionService

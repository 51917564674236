import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import accountsService from './accService';



const initialState = {
    isError: false,
    isLoading: false,
    isProcessing: false,
    isSuccess: false,
    isAddError: false,
    isAddLoading: false,
    isAddSuccess: false,
    isSearchError: false,
    isSearchLoading: false,
    isSearching: false,
    isSearchSuccess: false,
    message: '',
    searchAccs: [],

}


// getAccounts
export const getAccounts = createAsyncThunk('accounts/getAccounts', async (_, thunkApi) => {
    try {
        const usrId = thunkApi.getState().auth.user.usrId;
        return await accountsService.getAccounts(usrId);
    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})

//add account
export const addAccounts = createAsyncThunk('accounts/addAccounts', async (params, thunkApi) => {
    try {
        // const usrId = thunkApi.getState().auth.user.usrId;
        return await accountsService.addAccount(params);
    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})

//search account
export const searchAccounts = createAsyncThunk('accounts/searchAccounts', async (params, thunkApi) => {
    try {
        // const usrId = thunkApi.getState().auth.user.usrId;
        return await accountsService.searchAccounts(params);
    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})


export const accountsSlice = createSlice({
    name: 'accounts',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isProcessing = false
            state.isError = false
            state.isSuccess = false
            state.message = ''
            state.searchAccs = []
            state.isAddError= false
            state.isAddLoading= false
            state.isAddSuccess= false
            state.isSearchError= false
            state.isSearchLoading= false
            state.isSearching = false
            state.isSearchSuccess= false
        },
    },
    extraReducers: (builder) => {
        builder
            // get accounts
            .addCase(getAccounts.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAccounts.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.accounts = action.payload

            })
            .addCase(getAccounts.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.accounts = []
            })

            // add accounts
            .addCase(addAccounts.pending, (state) => {
                state.isProcessing = true
            })
            .addCase(addAccounts.fulfilled, (state, action) => {
                state.isProcessing = false
                state.isAddSuccess = true
                state.accounts.unshift(action.payload)
                state.message = 'Account added successfully'

            })
            .addCase(addAccounts.rejected, (state, action) => {
                state.isProcessing = false
                state.isAddError = true
                state.message = action.payload
            })

            // search accounts
            .addCase(searchAccounts.pending, (state) => {
                state.isSearching = true
            })
            .addCase(searchAccounts.fulfilled, (state, action) => {
                state.isSearching = false
                state.isSearchSuccess = true
                state.searchAccs = action.payload
            })
            .addCase(searchAccounts.rejected, (state, action) => {
                state.isSearching = false
                state.isSearchError = true
                state.message = action.payload
                state.searchAccs = []

            })

    }
})

export const { reset } = accountsSlice.actions
export default accountsSlice.reducer

import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TablePaginationActions from '../../components/pagination/TablePaginationActions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton'
import './notifications.css'


import 'react-loading-skeleton/dist/skeleton.css'
import { getNotificationPayment } from '../../features/notifications/notificationSlice';
import { useNavigate } from 'react-router-dom';
import { Badge, Card } from '@mui/material';
import { green, orange, red } from '@mui/material/colors'



const NotificationPayments = ({water}) => {
  console.log("waterreceived",water)
  const formatMsforHumans = (timestamp) => {
    if (!timestamp) return;
    let date = new Date(timestamp);

    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);

    return `${day}/${month}/${year}`;
  }
  const { notificationPayment,
    isLoading,
    isError,
    isSuccess,
    message } = useSelector((state) => state.notifications)
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false);
  const [selectedRfp, setSelectedRfp] = useState({});
  const [value, setValue] = useState(0);
  const [payment, setPayment] = useState("PAYMENT")
  const [others, setOthers] = useState("OTHER")

  const navigate = useNavigate()

  /**
   * :: Initialize pagination - starts here
   */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - notificationPayment.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  useEffect(() => {
    if (isError) {
      console.log(message)
    }
    dispatch(getNotificationPayment({
      // wacAccId: 50840,
      wacAccId: water.water,
      wacWtrId: 1,
      usrId: 1980,
      notiType: payment,
    }))
  }, [navigate, isError,water, message, dispatch])
  console.log("notificationPayment", notificationPayment)

  /** ::  Initialize pagination - ends here */

  return (
    <>

      <div className='container my-2'>
        <TableContainer>

          <Table sx={{ minWidth: { md: 500, xs: 'auto' } }} aria-label="custom pagination table">
            {isLoading ? (
              <Skeleton
                className="my-2"
                count={6}
                height={70}
                inline={false}
              />
            ) : (

              <TableBody>
                {notificationPayment.length && (
                  rowsPerPage > 0 ? Array.from(notificationPayment).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : notificationPayment).map((notification, index) => (
                    <>

                      <Card style={{ color: '#707070' }} class="notifpanes notf-for-payment pt1">
                        <p>
                          {notification.notiText}
                        </p>
                        <Badge style={{ display: "flex", width: "auto", justifyContent: "flex-end", color: notification.notiStatus === "PENDING" ? orange[400] : notification.notiStatus === "APPROVED" ? green[400] : red[400] }} >{notification.notiStatus}</Badge>
                        <div class="notf-date " style={{ display: "flex", justifyContent: { lg: "flex-end", xs: "flex-start", sm: "flex-start" } }}>{formatMsforHumans(notification.notiDate)}</div>

                      </Card>
                      <div className='breakers'></div>
                    </>
                  ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )}

            <TableFooter >
              <TableRow>
                {isLoading ? (
                  <Skeleton className="my-2" height={25} inline={false} />
                ) : (
                  <TablePagination sx={{ minWidth: { md: 500, xs: 'auto' } }}
                    rowsPerPageOptions={[5, 10, 15, { label: 'All', value: -1 }]}
                    colSpan={3}
                    count={notificationPayment.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                )}
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>

    </>
  )
}

export default NotificationPayments
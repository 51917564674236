import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import exhausterService from './exhausterService';

const initialState = {
    exhausterRequests: [],
    isLoading: false,
    isProcessing: false,
    isSuccess: false,
    isError: false,
    message: '',
}

export const getExhausterRequests = createAsyncThunk(
    'exhauster_requests',
    async(params, thunkAPI)=>{
        try {
            return await exhausterService.getExhausterRequests(params)
        } catch(error) {
            const message = handleFailure(error)  
            return thunkAPI.rejectWithValue(message)
        }
});

export const saveExhausterRequest = createAsyncThunk(
    'exhauster_request/save',
    async(bowser ,thunkAPI)=>{
        try {
            return await exhausterService.saveExhausterRequest(bowser)
        } catch(error) {
            const message = handleFailure(error)  
            return thunkAPI.rejectWithValue(message)
        }
});

export const exhausterSlice = createSlice({
    name:'exhauster',
    initialState,
    reducers:{
        reset:(state)=>{
           state.isLoading = false
           state.isProcessing = false
           state.isError = false
           state.isSuccess = false
           state.message = ''
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getExhausterRequests.pending, (state)=>{
            state.isLoading = true
        })
        .addCase(getExhausterRequests.fulfilled, (state,action) =>{
            state.isLoading = false
            state.isSuccess = true
            state.exhausterRequests = action.payload
        })
        .addCase(getExhausterRequests.rejected,(state,action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.exhausterRequests = []
        })
        .addCase(saveExhausterRequest.pending, (state)=>{
            state.isProcessing = true
        })
        .addCase(saveExhausterRequest.fulfilled, (state,action) =>{
            state.isProcessing = false
            state.isSuccess = true
            state.exhausterRequests.unshift(action.payload)
            state.message = 'Bowser request submitted'
        })
        .addCase(saveExhausterRequest.rejected,(state,action)=>{
            state.isProcessing = false
            state.isError = true
            state.message = action.payload
        })
    }

})

export const {reset} = exhausterSlice.actions
export default exhausterSlice.reducer
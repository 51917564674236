import React, { useState } from 'react'
// import Logo from '../../img/logo.svg';
import Logo from '../../img/ruiru_logo.jpeg';
import Acc from '../../img/acc.svg';
import Avatar from '../../img/avatar.jpg';
import { Link, useNavigate } from 'react-router-dom';
import AccountMenu from '../menu/AccountMenu';
import './header.css';
import { Logout } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { cleanupStep1Details } from '../../features/apply_new_connection/applyNewConnectionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { cleanPayAmount } from '../../features/bowser/bowserSlice';

const AppBar = () => {
    const {user} =  useSelector((state)=>state.auth)
    const navigate =  useNavigate();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleLogout=()=>{
        dispatch(cleanupStep1Details());
        dispatch(cleanPayAmount());
        navigate("/landing")
    }

    
  return (
    <nav class="navbar navbar-dark sticky-top flex-md-nowrap p-0">
            <div class="col-12 col-md-4 logo-xs"><Link class="navbar-brand logo_h" to="/home">
                <img src={Logo} alt="logo" style={{ width: '45%',objectFit:''}} />
                </Link></div>

            <div class="col-12 col-md-8 suspend-hdr">
            <ul class="nav dash-nav justify-content-end text-right">
                <li class="nav-item">
                <Link to="/home"><i class="icon ion-md-home"></i> <br/> <span>Home</span></Link>
                </li>
                <li class="nav-item"> 
                <Link to="/wizard">
                    <div class="acc-avatar">
                        <img class="img-fluid" src={Acc} alt="acc"/> <br/> 
                    </div>
                    <span>Accounts</span>
                </Link>
            </li>
            <li class="nav-item">
                <Link to="/notifications"><i class="icon ion-md-notifications"></i> <br/> <span>Notifications</span></Link>
            </li>

            <li class="nav-item text-center account-menu">
               <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                    <div class="acc-avatar">
                        <img src={Avatar} alt="acc" /> <br/>
                    </div>
                    <span>profile</span>
                </a>

                <div class="dropdown-menu">
                    <div className='header'>
                        <p>Signed in as</p>
                        <h6>{`${user.usrFirstName} ${user.usrLastName}`}</h6>
                    </div>
                    <div class="dropdown-divider"></div>
                    <Link to="/profile" class="dropdown-item">
                        Your Profile</Link>
                    <div class="dropdown-divider"></div>
                    <div  class="dropdown-item" style={{cursor:'pointer',textDecoration:'none'}} onClick={handleLogout}>
                        <Logout sx={{mr:.5, fontSize: '1rem'}}/> 
                        Logout
                        </div>
                </div>
                            
            </li>

        




            {/* <div 
                aria-label="more"
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className="d-flex justify-content-start user-profile-wrapper"
            >
                <li class="nav-item text-center">
                    <div class="acc-avatar">
                        <img src={Avatar} alt="acc" /> <br/>
                    </div>
                    <span>profile</span>
                </li>
            </div> */}
            </ul>

            {/* <AccountMenu anchorEl={anchorEl} open={open} handleClick={handleClick} handleClose={handleClose} /> */}
        </div>
     </nav>
  )
}

export default AppBar

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
            fontSize: '16px',
            width: 34,
            height: 34,
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}
import React, { useEffect, useState } from 'react';
import otherServices from '../../img/colored/other_services.svg';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getWaterCompanies } from '../../features/wsp/wspSlice';
import { CircularProgress, TextField } from '@mui/material';
import { formatMsToDate } from '../../utils';
import { saveOtherRequest, reset,  getOtherRequests } from '../../features/other/otherSlice';
import CardSkeleton from '../../components/CardSkeleton';

const OtherServices = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { waterCompanies,  } = useSelector(state => state.wsp);
    const { isProcessing, otherRequests, isLoading, isSuccess, isError, message} = useSelector(state => state.other)
    const [formData, setFormData] = useState({
        phone: '',
        location: '',
        date: '',
        wsp: '',
        service: '',
    });

    const {phone, location, wsp, date, service} = formData;

    const handleChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name] : e.target.value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const _exhauster = {
            sreqBowserCount: 1,
            sreqContactMobile: user.usrUsername,
            sreqContactName: `${user.usrFirstName} ${user.usrLastName}`,
            sreqStatus: 'PENDING',
            sreqWaterProviderId: wsp,
            sreqLocation: location,
            sreqType: service,
            sreqUsrId: user.usrId,
            sreqDateExpected: date,
            // sreqDestLatitude: -1.3403850000000001596589527252945117652416229248046875,
            // sreqDestLongitude: 36.69090166666666874561997246928513050079345703125,
        }

        if (!wsp) {
            toast.warning("Please select water service provider");
        } else {
            dispatch(saveOtherRequest(_exhauster));
            if (!isProcessing) {
                setFormData((prevState) => ({
                    ...prevState,
                    phone: '',
                    location: '',
                    date: '',
                    wsp: '',
                    service: ''
                }))
            }
        }
    }

    const calcPrice =(b) => {
        return b.sreqUnitCost * b.sreqBowserCount + b.sreqMileageUnitCost
    }

    useEffect(() => {
        dispatch(getOtherRequests({
            wacWtrId: 1,
            usrId: user.usrId,
            sreqType: 'OTHER'
        }));

        console.log("otherRequests", otherRequests);
        if (isSuccess && message) {
            toast.success(message);
        }
        if (!waterCompanies?.length) {
            dispatch(getWaterCompanies())
        }
        
        return () => {
            dispatch(reset());
        }
    }, [toast, message, dispatch])

  return (
    <>
        <main role="main" class="col-12 col-md-9 ml-sm-auto col-lg-10 pt-3 px-4 main-window">
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-1">
                <h4 class="page-intros">Other services</h4>
            </div>

            <div className='row'>
            <div class="col-12 col-md-5">
                <div class="card">
                <div class="card-body">
                    <h4 class="water-blue">Request other services</h4>
                    <p>Is your meter having issues? Request any of the service below.</p>
                    <p><i>NOTE: Testing costs KES 250</i></p>
                    <div class="row  for-billing">


                        {/* <div class="container-fluid mt1 ">
                        <div class="row for-billing pb2">

                            <div class="col">
                            <div class="the-form mt1">

                                <form>
                                <div class="form-group">
                                    <label for="datepicker" class="date-label"> <i class="icon ion-md-calendar"></i> Select a testing date </label>
                                    <input id="datepicker" class="form-control col mt0" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="07/05/2019" />
                                </div>

                                <button type="submit" class="mt1 col nav-link-def">Request a meter testing</button>

                                </form>
                            </div>
                            </div>

                        </div>
                        </div> */}

                    </div>

                    <div class="the-form mt2 for-billing">
                        <div className='col'>
                            
                        <form onSubmit={handleSubmit} class="mt2">

                            <div class="form-group">
                                <label for="exampleFormControlSelect1">Select other services</label>
                                <select class="form-control" id="exampleFormControlSelect1" name='service' onChange={handleChange} required>
                                    <option value="">-- Select --</option>
                                    <option value="METER_INSPECTION">METER_INSPECTION</option>
                                    <option value="METER_REPLACEMENT">METER_REPLACEMENT</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label for="phone">Contact phone number</label>
                                <input type="text" name="phone" 
                                    value={phone ? phone : user.usrUsername} 
                                    onChange={handleChange} class="form-control" 
                                    id="phone" aria-describedby="emailHelp"  
                                    placeholder="Contact phone number" />
                            </div>

                            <div class="form-group mt2">
                                <label for="location">Location</label>
                                <input type="text" name="location" 
                                    value={location}
                                    onChange={handleChange} class="form-control" id="location"
                                    placeholder="eg karen" required />
                            </div>

                            <div class="form-group mt2">
                                <label for="date">Service date</label>
                                <input type="date" name="date" 
                                    value={date}
                                    onChange={handleChange} class="form-control" id="date"
                                    placeholder="Date expected" required />
                            </div>

                            <div class="form-group">
                                <label for="exampleFormControlSelect1">Water service providers</label>
                                <select class="form-control" id="exampleFormControlSelect1" name='wsp' onChange={handleChange}>
                                    <option value="">-- Select --</option>
                                    {waterCompanies?.length &&  waterCompanies?.map(waterCompany => (
                                        <option value={waterCompany.wtrId} key={waterCompany.wtrId}>{waterCompany.wtrCompany}</option>
                                    ))}
                                </select>
                            </div>

                            {!isProcessing ? (
                                <button type="submit" class="mt1 button nav-link">Submit</button>
                            ) : (
                                <button style={{'textTransform': 'none'}} class="nav-link-light disabled" disabled>
                                    <CircularProgress size={15} color="inherit" />&nbsp; Submitting
                                </button>
                            )}

                            </form>
                        </div>
                    </div>

                </div>
            </div>
            </div>

            <div class="col-12 col-md-5">
                <div class="card">
                    <div class="card-body">
                    <h5 class="card-title">My Requests</h5>
                    <div class="row">
                            {isLoading ? (<><CardSkeleton/></>) :(
                                    <>
                                        {otherRequests.length && otherRequests.map((other) => (
                                        <>
                                        <div class="container-fluid mt1">
                                                <div class="row for-readings pt1">

                                                    <div class="col pb1">
                                                    <div class="row">
                                                    <div class="col">
                                                        <div class="row">
                                                        <div class="col-4 notf-img"><img src={otherServices} alt="other_services" /></div>
                                                        <div class="col-8 pull-right text-right r-date"> { formatMsToDate(other.sreqCreatedDate) }</div>
                                                        </div>
                                                    </div>
                                                    </div>

                                                    <div class="row mt0">
                                                    <div class="col-6 notf">
                                                        <p>{other.sreqLocation}</p>
                                                        <p>{other.sreqType}</p>
                                                        <p>KES { calcPrice(other) }</p>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="row">
                                                        <div class="col text-right">
                                                            <span style={{color: '#b8b8b8;'}}><i class="icon ion-md-checkmark-circle"></i></span> {other.sreqStatus}
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>

                                                <div class="card-footer "> {/* notf-approved */}
                                                <div class="row">
                                                    <div class="col-6 text-left"><a class="nav-link-shy" href="#">Cancel <i class="icon ion-md-arrow"></i></a></div>
                                                    <div class="col-6 text-right"><a class="nav-link-def" href="#">Pay now <i class="icon ion-md-arrow"></i></a></div>
                                                </div>
                                                </div>
                                            </div>
                                        </>
                                        ))}
                                    </>
                                )}

                        </div>
                </div>
            </div>
  </div>

            </div>

            

        </main>
    </>
  )
}

export default OtherServices
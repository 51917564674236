import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getAccounts } from "../../features/Account/accSlice";
import { getMpesa } from "../../features/mpesa/mpesaSlice";
import { toast } from "react-toastify";
import { TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Mpesa = ({ account, amountreceived }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        phoneNumber: '',
        amount: '',
    });

    console.log("received account mpesa",account)

    const { phoneNumber, amount } = formData;

    const { user } = useSelector((state) => state.auth)

    const { mpesa, isError, isLoading, isSucess, message } = useSelector((state) => state.mpesa);

    const { bowserExhaustAmount, bowserPayment, exhaustPayment } = useSelector((state) => state.bowser)


    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const handlCancel = () => {
        if (exhaustPayment) {
            navigate('/exhauster')
        } else {
            navigate('/bowser');
        }
    }


    const notify = () => toast.success(' Please check your phone!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
    const saveMpesa = async (e) => {
        e.preventDefault()

        if (bowserPayment) {
            dispatch(getMpesa({
                phone: formData.phoneNumber || user.usrMobileNumber,
                amount: bowserExhaustAmount,
                accountref: 1,
            }))
        } else {
            dispatch(getMpesa({
                phone: formData.phoneNumber || user.usrMobileNumber,
                amount: formData.amount || amountreceived,
                accountref: account,
            }))
        }

        if (isSucess) {
            notify()
        }
    }

    useEffect(() => {
        if (isError) {
            console.log(message)
        }


    }, [isError, message, dispatch])



    return (

        <div className="the-form mt1">
            <p>Enter phone number you will receive a prompt on your phone to <br />complete
                payment.</p>

            <form>

                <div className="form-group">
                    <input
                        type="phone"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Phone number"
                        name='phoneNumber'
                        value={phoneNumber ? phoneNumber : user.usrMobileNumber}
                        onChange={onChange}
                    />
                </div>

                <div className="form-group">
                    {
                        bowserPayment || exhaustPayment ? (
                            <input
                                type="number"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Amount"
                                name='amount'
                                value={bowserExhaustAmount}
                                onChange={onChange}
                            />
                        ) : (
                            <input
                                type="number"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Amount"
                                name='amount'
                                value={amount ? amount : amountreceived}
                                onChange={onChange}
                            />
                        )
                    }


                </div>

                <div className="form-check mt1">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                    <label className="form-check-label" htmlFor="exampleCheck1">Always
                        use Mpesa selected <br /> mode of payment</label>
                </div>

                <div className='row mt-3 ' style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {
                        (bowserPayment || exhaustPayment) &&
                        <div class="col-6">
                            <a class="nav-link-shy" onClick={() => handlCancel()}
                                href="#">Cancel <i class="icon ion-md-arrow"></i></a>
                        </div>
                    }

                    <div class="col-6">
                        <a class="nav-link-def"
                            onClick={saveMpesa}
                            href="#">Submit<i class="icon ion-md-arrow"></i></a>
                    </div>
                </div>
                {/* <button type="submit" className="mt2 button nav-link" onClick={saveMpesa}>Submit</button> */}
            </form>
        </div>
    );
}

export default Mpesa;
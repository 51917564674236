import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import authService from './authService';

const user = JSON.parse(localStorage.getItem('user'))
const initialState={
    user: user ? user:null,
    isError: false,
    isLoading: false,
    isProcessing: false,
    isSuccess: false,
    message: '',
}

export const register = createAsyncThunk(
    'auth/register',
    async(user,thunkAPI)=>{
        try {
            return await authService.register(user)
        } catch(error) {
            const message = handleFailure(error)  
            return thunkAPI.rejectWithValue(message)
        }
});

export const login =createAsyncThunk(
    'auth/login',
    async(user,thunkAPI)=>{
    try{
        return await authService.login(user)
    }catch(error){
        const message = handleFailure(error)  
        return thunkAPI.rejectWithValue(message)
        }
});

export const forgotPass = createAsyncThunk(
    'auth/forgot-pass',
    async(userId, thunkAPI) => {
        try {
           return await authService.forgotPass(userId) 
        } catch (error) {
            const message = handleFailure(error)  
            return thunkAPI.rejectWithValue(message)
        }
    }
);
 
export const authSlice=createSlice({
     name:'auth',
     initialState,
     reducers:{
         reset:(state)=>{
            state.isLoading = false
            state.isProcessing = false
            state.isError = false
            state.isSuccess = false
            state.message = ''
         }
     },
     extraReducers:(builder)=>{
         builder
         .addCase(login.pending, (state)=>{
             state.isProcessing = true
         })
         .addCase(login.fulfilled, (state,action) =>{
             state.isProcessing = false
             state.isSuccess = true
             state.user = action.payload
             state.message = "Sign In Successful."
         })
         .addCase(login.rejected,(state,action)=>{
             state.isProcessing = false
             state.isError = true
             state.message = action.payload
             state.user = null
         })
        .addCase(register.pending, (state)=>{
            state.isProcessing = true
        })
        .addCase(register.fulfilled, (state,action) =>{
            state.isProcessing = false
            state.isSuccess = true
            state.message = "Signup successfull, you may now login."
            state.user = action.payload
        })
        .addCase(register.rejected,(state,action)=>{
            state.isProcessing = false
            state.isError = true
            state.message = action.payload
            state.user = null
        })
        .addCase(forgotPass.pending, (state)=>{
            state.isProcessing = true
        })
        .addCase(forgotPass.fulfilled, (state,action) =>{
            state.isProcessing = false
            state.isSuccess = true
            state.message = "Password reset request successfull."
            state.user = null
        })
        .addCase(forgotPass.rejected,(state,action)=>{
            state.isProcessing = false
            state.isError = true
            state.message = action.payload
        })
     }
 })

export const {reset} = authSlice.actions
export default authSlice.reducer
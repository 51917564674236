import {
    Card,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    DialogContentText,
    IconButton,
    MenuItem,
    TextField,
    FormControlLabel,
    Checkbox, TableContainer, Table, TableHead, TableRow, TableCell, TableBody
} from '@mui/material';
import {
    Article,
    Circle,
    Today,
    InsertInvitation,
    Download,
    Assignment,
    ArrowForwardIosSharp,
    AssignmentOutlined
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import './invoice.css';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { postApplyLoanNow } from '../../../features/loanee/loaneeSlice';
import { getLoaneeSchedule, reset } from '../../../features/loanee/loaneeSlice';
import {getPeriods} from "../../../features/view_bill/viewBillSlice";
import {getAccounts} from "../../../features/accounts/accountSlice";
import {formatMsToDate} from "../../../utils";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontWeight: 'bold' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
const periods = [
    {
        value: 1,
        label: '1 month',
    },
    {
        value: 2,
        label: '2 month',
    },
    {
        value: 3,
        label: '3 month',
    },
    {
        value: 4,
        label: '4 month',
    },
    {
        value: 5,
        label: '5 month',
    },
    {
        value: 6,
        label: '6 month',
    },
    {
        value: 7,
        label: '7 month',
    },
    {
        value: 8,
        label: '8 month',
    },
    {
        value: 9,
        label: '9 month',
    },
    {
        value: 10,
        label: '10 month',
    },
    {
        value: 11,
        label: '11 month',
    },
    {
        value: 12,
        label: '12 month',
    },
];
const Loan = ({ open, handleOpenLoan, handleCloseLoan, invoice, lnWtrId,handleCloseInvoice,amount }) => {

    const [repaymentMonths, setRepaymentMonths] = useState('')

    console.log("repaymentMonths", repaymentMonths)
    console.log("repaymentamount", amount)

    const { user } = useSelector(state => state.auth);

    const { loanee, loaneeSchedule, isErrorLoanee, isLoadingLoanee, message, isSuccessLoanee } = useSelector((state) => state.loanee)

    console.log("isSuccessLoanee", isSuccessLoanee)

    const dispatch = useDispatch()

    const  handleChange = (e) => {
        // console.log("kelokja", e.target.value)
        setRepaymentMonths(e.target.value);

        console.log('Right Now', repaymentMonths)

        dispatch(getLoaneeSchedule({
            wacWtrId: 1,
            amount: amount,
            months: repaymentMonths,
            usrId: 1979,
        }));

    }

    const saveLoanNow = async (event) => {

        event.preventDefault()
        const _formData = {
            lnAccId: 662,
            lnUsrId: user.usrId,
            lnCustId: 662,
            lnAmount: amount,
            lnInterest: 130,
            lnWtrId: 1,
           lnMonths: repaymentMonths,
        }
        if (!repaymentMonths) {
            toast.error("Please  select repayment period")
            dispatch(reset)
        }

        if (repaymentMonths) {
            dispatch(postApplyLoanNow(_formData))
            toast.success("Application successfull")
            dispatch(reset())
            setRepaymentMonths('')
            handleCloseLoan()
            handleCloseInvoice()
        }
    }

    const navigate = useNavigate()


    useEffect(() => {
        dispatch(getLoaneeSchedule({
            wacWtrId: 1,
            amount: amount,
            months: repaymentMonths,
            usrId: 1979,
        }));
    }, [navigate,repaymentMonths, isErrorLoanee, message, dispatch])




    return (
        <>
            <Dialog
                open={open}
                onClose={handleCloseLoan}
                fullWidth={true}
                maxWidth="sm"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ borderRadius: 5 }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseLoan}>
                    Loan Information
                </BootstrapDialogTitle>
                <DialogContent sx={{ px: 1, }} dividers>
                   <Paper sx={{ p: 1}}>
                       <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                           <Grid item xs={12} md={12} style={{ display: 'flex', alignItems: 'center' }}>
                               <Grid item xs={6} md={6} style={{ fontWeight: '600' }}>
                                   Repayment Period
                               </Grid>
                               <Grid item xs={6} md={6} style={{ marginTop: '0!important' }}>
                                   <TextField
                                       sx={{ marginTop: '0!important', borderStyle: "none!important" }}
                                       id="outlined-select-repayment-period"
                                       select
                                       fullWidth
                                       name="period"
                                       label="period"
                                       variant="outlined"
                                       onChange={handleChange}
                                   >
                                       {periods && periods.map((option) => (
                                           <MenuItem key={option.value} value={option.value}>
                                               {option.label}
                                           </MenuItem>
                                       ))}
                                   </TextField>
                               </Grid>
                           </Grid>

                           <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                               <Grid item xs={6} md={6} style={{ fontWeight: '600' }} style={{ fontWeight: '600' }}>
                                   Amount
                               </Grid>
                               <Grid item xs={6} md={6}>
                                   {amount}
                               </Grid>
                           </Grid>

                           <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                               <Grid item xs={6} md={6} style={{ fontWeight: '600' }}>
                                   Interest
                               </Grid>
                               <Grid item xs={6} md={6}>
                                   kes 130
                               </Grid>
                           </Grid>

                           <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                               <Grid item xs={6} md={6} style={{ fontWeight: '600' }}>
                                   Convenience Fee
                               </Grid>
                               <Grid item xs={6} md={6}>
                                   kes 15
                               </Grid>
                           </Grid>

                           <Grid item xs={12} md={12} style={{ display: 'flex', fontWeight: 'bold' }}>
                               <Grid item xs={8} md={6} style={{ fontWeight: '600' }}>
                                   Total
                               </Grid>
                               <Grid item xs={4} md={6}>
                                   KES 1145
                               </Grid>
                           </Grid>
                       </Grid>
                   </Paper>

                    <>
                        {isLoadingLoanee ? (
                            <Skeleton
                                style={{ marginTop: '.75rem'}}
                                count={4}
                                height={25}
                                inline={false}
                            />
                        ) : (
                            <>
                                <TableContainer sx={{ mt: .75}} >
                                    <Table sx={{ }} aria-label="simple table" size="small" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 'bold'}}>Amount</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold'}} align="left">Due date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {loaneeSchedule && loaneeSchedule.map((attachment,index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {attachment.lnsInstAmount}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {formatMsToDate(attachment.lnsDueDate)}
                                                    </TableCell>

                                                </TableRow>
                                            ))}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        )}
                    </>
                </DialogContent >
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <FormControlLabel control={<Checkbox />} label="Agree to    Terms & Conditions " />
                </div>
                <DialogActions sx={{my: 0}}>
                    <Button variant="contained"
                            sx={{
                                width: '100%',
                                border: 'none', textTransform: 'none', outline: '2px solid transparent', outlineOffset: '2px',
                                backgroundColor: `var(--water-blue)!important`,
                                color: 'white !important',
                                borderRadius: '0.5rem', fontWeight: '600', paddingLeft: '3rem', paddingRight: '3rem',
                                paddingTop: '0.5rem', paddingBottom: '0.5rem'
                            }}
                            onClick={saveLoanNow}
                    >
                        Get Loan Now
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Loan
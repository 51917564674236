import axios from "axios"

const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;


// getAccounts
const getAccounts = async (usrId) => {
    const response = await axios.get(`${API_URL}/usr/fetchWaterAccounts.action?usrId=${usrId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    const result = response.data.jsonData;
    response.data.jsonData = [];
    return result;
}

// add account
const addAccount = async (_formData) => {
    console.log("_formData",_formData.wacUsrId)
    const response = await axios.post(`${API_URL}/usr/postWaterAccount.action?usrId=${_formData.wacUsrId}`, _formData)
    console.log(response);
    const result = response.data;
    response.data = [];
    return result;
}

const searchAccounts = async (data) => {
    const response = await axios.get(`${API_URL}/usr/searchCustomerAlt.action?wacWtrId=${data.wacWtrId}&cAccNumber=${data.cAccNumber}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    const result = response.data.jsonData;
    response.data.jsonData = [];
    console.log('Source -------', result)
    return result;
}


const accountsService = {
    getAccounts,
    addAccount,
    searchAccounts,

}
export default accountsService

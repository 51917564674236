import { Button, Card, Checkbox, FormControlLabel, Grid, MenuItem, TextField } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { getInvoice } from '../../features/invoice/invoiceSlice'
import { getWaterCompanies } from '../../features/wsp/wspSlice'
import './apply.css'
import Invoice from './invoice/Invoice'

const periods = [
    {
        value: '1 month',
        label: '1 month',
    },
    {
        value: '2 month',
        label: '2 month',
    },
    {
        value: '3 month',
        label: '3 month',
    },
    {
        value: '4 month',
        label: '4 month',
    },
    {
        value: '5 month',
        label: '5 month',
    },
    {
        value: '6 month',
        label: '6 month',
    },
    {
        value: '7 month',
        label: '7 month',
    },
    {
        value: '8 month',
        label: '8 month',
    },
    {
        value: '9 month',
        label: '9 month',
    },
    {
        value: '10 month',
        label: '10 month',
    },
    {
        value: '11 month',
        label: '11 month',
    },
    {
        value: '12 month',
        label: '12 month',
    },



];
function ApplyNewConnections() {
    const [value, setValue] = React.useState(null);
    const [openInvoice, setOpenInvoice] = useState(false)

    const handleOpenInvoice = () => {
        handleSearchInvoice()
        setOpenInvoice(true);

    };

    const handleCloseInvoice = () => {
        setOpenInvoice(false);
    };

    const [formData, setFormData] = useState({
        waterProvider: "",
        invoiceNo: "",

    })
    const { waterProvider, invoiceNo } = formData
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth);
    const { waterCompanies, isLoading, isError, isSuccess, message } = useSelector((state) => state.wsp)
    const { invoice, isErrorInvoice, isLoadingInvoice, isSuccessInvoice } = useSelector((state) => state.invoice)

    useEffect(() => {
        if (isError) {
            console.log(message)
        }
        dispatch(getWaterCompanies())
    }, [navigate, isError, message, dispatch])

    console.log("waterCompanies", waterCompanies)


    const handleSearchInvoice = () => {
        dispatch(getInvoice({
            wacWtrId: formData.waterProvider,
            invoCode: formData.invoiceNo,
        }))
    }

    console.log("invoice", invoice)
    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
        console.log("form data", formData)
    }

    return (
        <div>


            <Grid container spacing={2} style={{ marginTop: '.5rem', }} >
                <Grid item xs={12} md={12} >
                    <Card style={{ padding: '15px' }}>
                        <Grid container spacing={2} style={{ marginBottom: '0px', }}>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    id="outlined-select-water-provider"
                                    select
                                    fullWidth
                                    name="waterProvider"
                                    label="Select water Provider"
                                    variant="standard"
                                    onChange={onChange}
                                >
                                    {waterCompanies.map((option) => (
                                        <MenuItem key={option.wtrId} value={option.wtrId}>
                                            {option.wtrCompany}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={12} md={12} style={{ marginTop: '7px' }}>
                                <input type="text"
                                    class="form-control"
                                    placeholder="Enter Invoice Number"
                                    onChange={onChange}
                                    name="invoiceNo"
                                    id="invoiceNo" 
                                   
                                    />
                            </Grid>

                            <Grid item xs={12} md={12} style={{ marginTop: '0px' }}>
                                <div class="forgot-pass text-left">
                                    <h6 style={{fontSize: '15px'}}>
                                        <Link to="/connect" class="tt-initial">
                                            Don't have Invoice / Connection fee ?
                                        </Link>
                                    </h6>
                                </div>
                            </Grid>
                           
                        </Grid>
                    
                    {/* <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <FormControlLabel control={<Checkbox />} label="Agree to    Terms & Conditions " />
                    </div> */}

                    <div style={{ textAlign: 'center' }}>
                        <Button variant="contained"
                            // disabled={!startDates || !endDates ? true : false}
                            sx={{
                                width: '100%',
                                border: 'none', textTransform: 'none', outline: '2px solid transparent', outlineOffset: '2px',
                                backgroundColor: `var(--water-blue)!important`,
                                color: 'white !important',
                                borderRadius: '0.5rem', fontWeight: '600', paddingLeft: '3rem', paddingRight: '3rem',
                                paddingTop: '0.5rem', paddingBottom: '0.5rem'
                            }}
                            onClick={handleOpenInvoice}
                        >
                            Search
                        </Button>

                        <Grid container style={{marginTop:'10px'}}>
                            {/* <Grid item>
                            Do you have an invoice / connection fee from a water company?
                                <Link to="/connect"
                                    variant="body2"
                                >
                                    {"Create Account"}
                                </Link>
                            </Grid> */}
                        </Grid>
                    </div>

                    </Card>

                </Grid>
            </Grid>



            <Invoice open={openInvoice} handleOpenInvoice={handleOpenInvoice} handleCloseInvoice={handleCloseInvoice} isLoadingInvoice={isLoadingInvoice} invoice={invoice} lnWtrId={formData.waterProvider} />

        </div>
    )
}

export default ApplyNewConnections
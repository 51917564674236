import React from 'react'
import mpesa from '../assets/img/elements/mpesa-logo.png';
import creditCard from '../assets/img/elements/credit-card-1.png';
import element from '../assets/img/elements/element1.svg';
import bank from '../assets/img/elements/Bank-1.png';
import MpesaLipaMaji from './mpesa/MpesaLipaMaji';

function Payments() {
    return (
         <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4 main-window">
            <div className="col-md-6 col-12 for-payments">
                <h4 className="dash-intro">Select your mode of payment</h4>

                <div className="row">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item">

                            <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home"
                                role="tab" aria-controls="pills-home" aria-selected="true">
                                <div className="main-methods the-lefts">
                                    <div className="mpesa pay-shell">
                                        <img src={mpesa} alt="" />
                                    </div>
                                </div>
                            </a>
                        </li>

                        <li>
                            <div className="method-space the-lefts">
                                <img src={element} alt="" />
                            </div>
                        </li>

                        <li className="nav-item">

                            <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile"
                                role="tab" aria-controls="pills-profile" aria-selected="false">
                                <div className="main-methods the-lefts">
                                    <div className="mpesa pay-shell">
                                        <img src={creditCard} alt="" />
                                    </div>
                                </div>
                            </a>
                        </li>

                        <li>
                            <div className="method-space the-lefts">

                                <img src={element} alt="" />

                            </div>
                        </li>

                        <li className="nav-item">

                            <a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact"
                                role="tab" aria-controls="pills-contact" aria-selected="false">
                                <div className="main-methods the-lefts">
                                    <div className="mpesa pay-shell">
                                        <img src={bank} alt="" />
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>


                </div>

                <hr className="row" />


                <div className="row">
                    <div className="tab-content" id="pills-tabContent">

                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                            aria-labelledby="pills-home-tab">
                            <MpesaLipaMaji/>
                        </div>

                        <div className="tab-pane fade" id="pills-profile" role="tabpanel"
                            aria-labelledby="pills-profile-tab">
                            <div className="the-form mt1">
                                <p>Enter your card details below.</p>

                                <form>
                                    <div className="form-group">

                                        <input type="text" className="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Name" />
                                    </div>

                                    <div className="form-group">
                                        <input type="text" className="form-control"
                                            id="exampleInputPassword1" placeholder="Card number" />
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col-6">
                                                    <input type="text" className="form-control"
                                                        placeholder="MM" />
                                                </div>
                                                <div className="col-6">
                                                    <input type="text" className="form-control"
                                                        placeholder="YY" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col pull-right">
                                            <input type="text" className="form-control"
                                                placeholder="CVV CODE" />
                                        </div>
                                    </div>

                                    <div className="form-check mt2">
                                        <input type="checkbox" className="form-check-input" id="payment" />
                                        <label className="form-check-label" htmlFor="payment">Always use
                                            Mpesa selected mode of payment</label>
                                    </div>

                                    <div className="form-check mt1">
                                        <input type="checkbox" className="form-check-input" id="autoBill" />

                                        <label className="form-check-label" htmlFor="autoBill">Enable
                                            Auto Billing</label>
                                    </div>

                                    <div className="form-check mt1">
                                        <input type="checkbox" className="form-check-input" id="terms" />
                                        <label className="form-check-label" htmlFor="terms">Accept <a
                                            href="#">Terms & Conditions</a></label>
                                    </div>

                                    <button type="submit" className="mt2 button nav-link text">Submit</button>
                                    

                                </form>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="pills-contact" role="tabpanel"
                            aria-labelledby="pills-contact-tab">
                            <div className="the-form mt1">
                                <p><b>NOTE: </b>Use the bank details below</p>

                                <h6>1. Bank - KCB KIMATHI BRANCH</h6>
                                <h6>2. Account 123 456</h6>

                                <form className="mt2">
                                    <div className="form-group">
                                        <input type="number" className="form-control"
                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                            placeholder="Amount you want to pay" />
                                    </div>

                                    <button type="submit" className="mt2 button nav-link">Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </main>
    )
}

export default Payments
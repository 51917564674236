import axios from "axios"

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;
 
const getExhausterRequests = async({ wacWtrId, usrId, sreqType})=>{
    const response = await axios.get(`${API_URL}/usr/fetchServiceRequest.action?wacWtrId=${wacWtrId}&usrId=${usrId}&sreqType=${sreqType}`, {
        headers: {'Accept': 'application/json'}   
    })

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.jsonData
}

const saveExhausterRequest = async (exhauster) => {
    const response = await  axios.post(`${API_URL}/usr/postServiceRequest.action?wacWtrId=${exhauster.sreqWaterProviderId}`, exhauster, {
        headers: {'Accept': 'application/json'}
    })
    // return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.jsonData;
    return response.data;
}

const exhausterService = {
    getExhausterRequests,
    saveExhausterRequest,
}
export default exhausterService

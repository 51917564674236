import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import loaneeService from './loaneeService';



const initialState = {
    loanee: [],
    loaneeSchedule: [],
    isErrorLoanee : false,
    isLoading: false,
    isLoadingLoanee: false,
    isSuccessLoanee: false,
    message: '',

}


// getLoanee
export const getLoanee = createAsyncThunk('loanee/getLoanee', async (params, thunkApi) => {
    try {

        return await loaneeService.getLoanee(params);
    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})

// postApplyLoanNow
export const postApplyLoanNow = createAsyncThunk('loanee/postApplyLoanNow', async (formData, thunkAPI) => {

    try {
        return await loaneeService.postApplyLoanNow(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

export const getLoaneeSchedule = createAsyncThunk('loanee/getLoanSchedule', async (params, thunkAPI) => {

    try {
        return await loaneeService.getLoaneeSchedule(params)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});




export const loaneeSlice = createSlice({
    name: 'loanee',
    initialState,
    reducers: {
        reset:(state) => {
            state.isErrorLoanee = false
            state.isLoading = false
            state.isLoadingLoanee = false
            state.isSuccessLoanee = false
            state.message = ''
            // isErrorLoanee : false,
         }
    },
    extraReducers: (builder) => {
        builder
            // loanee
            .addCase(getLoanee.pending, (state) => {
                state.isLoading = true
                state.isLoadingLoanee = true
            })
            .addCase(getLoanee.fulfilled, (state, action) => {
                state.isLoading = false
                state.isLoadingLoanee = false
                state.isSuccessLoanee = true
                state.loanee = action.payload
            })
            .addCase(getLoanee.rejected, (state, action) => {
                state.isLoading = false
                state.isLoadingLoanee= false
                state.isErrorLoanee= true
                state.loanee = []
                state.message = action.payload
            })

            // applyLoanNow
            .addCase(postApplyLoanNow.pending, (state) => {
                state.isLoadingLoanee = true
            })
            .addCase(postApplyLoanNow.fulfilled, (state, action) => {
                state.isLoadingLoanee = false
                state.isSuccessLoanee = true
                state.loanee.unshift(action.payload)

            })
            .addCase(postApplyLoanNow.rejected, (state, action) => {
                state.isLoadingLoanee= false
                state.isErrorLoanee= true
                state.message = action.payload
                state.loanee.unshift(action.payload)
            })

            // Loan Schedule
            .addCase(getLoaneeSchedule.pending, (state) => {
                state.isLoadingLoanee = true
            })
            .addCase(getLoaneeSchedule.fulfilled, (state, action) => {
                state.isLoadingLoanee = false
                state.isSuccessLoanee = true
                state.loaneeSchedule = action.payload

            })
            .addCase(getLoaneeSchedule.rejected, (state, action) => {
                state.isLoadingLoanee= false
                state.isErrorLoanee= true
                state.message = action.payload
                state.loaneeSchedule = []
            })
    }
})

export const { reset } = loaneeSlice.actions
export default loaneeSlice.reducer
import axios from "axios"

const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;


// getAccounts
const getMeter = async ({mtrWacAccNumber}) => {
    const response = await axios.get(`${API_URL}/usr/fetchMeterReadings.action?mtrWacAccNumber=${mtrWacAccNumber}`)
    const result = response.data.jsonData;
    response.data.jsonData = [];
    return result;
}

const saveMeter = async (_formData) => {
    console.log("_formDatamtrAccId",_formData.mtrAccId)
    const response = await axios.post(`${API_URL}/usr/postMeterReading.action?wacAccId=${_formData.mtrAccId}`,_formData)
    if (response.status != 200) {
        return Promise.reject(response.data.messages.mainMessage) 
    }
    const result = response.data.jsonData;
    // response.data.jsonData = [];
    return result;
}


const MeterService = {
    getMeter,
    saveMeter,

}
export default MeterService

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import axios from "axios"
import MeterService from "./meterService";
import { arrCleanUp } from '../../utils';



const initialState = {
    meter: [],
    // saved: [],
    isMeterError: false,
    isMeterLoading: false,
    isMeterSuccess: false,
    messages: '',

}


// getAccounts
export const getMeter = createAsyncThunk('accounts/getMeter', async (params, thunkApi) => {
    try {
        // const usrId = thunkApi.getState().auth.user.usrId;
        return await MeterService.getMeter(params);
    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})

export const saveMeter = createAsyncThunk('applicationDetails/saveMeter', async (_formData, thunkAPI) => {
    try {
        return await MeterService.saveMeter(_formData)

    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});


export const MeterSlice = createSlice({
    name: 'meter',
    initialState,
    reducers: {
        reset:(state)=>{
            state.isMeterError = false
            state.isMeterLoading = false
            state.isMeterSuccess = false
            state.messages = ''
         }

    },
    extraReducers: (builder) => {
        builder
            // accounts
            .addCase(getMeter.pending, (state) => {
                state.isMeterLoading = true
            })
            .addCase(getMeter.fulfilled, (state, action) => {
                state.isMeterLoading = false
                state.isMeterSuccess = true
                state.meter = arrCleanUp(action.payload,"mtrId")

            })
            .addCase(getMeter.rejected, (state, action) => {
                state.isMeterLoading = false
                state.isMeterError = true
                state.messages = action.payload
            })

            .addCase(saveMeter.pending, (state) => {
                state.isMeterLoading = true
            })
            .addCase(saveMeter.fulfilled, (state, action) => {
                state.isMeterLoading = false
                state.isMeterSuccess = true
                state.meter.unshift(action.payload)
            })
            .addCase(saveMeter.rejected, (state, action) => {
                state.isMeterLoading = false
                state.isMeterError = true
                state.messages = action.payload
            })


    }
})

export const { reset } = MeterSlice.actions
export default MeterSlice.reducer
import axios from "axios"

const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;

 
// getNotificationPayment
const getNotificationPayment = async ({ wacAccId, wacWtrId, usrId, notiType }) => {
  const response = await axios.get(`${API_URL}/usr/fetchNotification.action?wacAccId=${wacAccId}&wacWtrId=${wacWtrId}&usrId=${usrId}&notiType=${notiType}`)
  if (!response.data.success) {
    return Promise.reject(response.data.messages.mainMessage) 
}
  const result = response.data.jsonData;
  response.data.jsonData = [];
  return result;
}
// getNotificationOthers
const getNotificationOthers = async ({ wacAccId, wacWtrId, usrId, notiType }) => {
  const response = await axios.get(`${API_URL}/usr/fetchNotification.action?wacAccId=${wacAccId}&wacWtrId=${wacWtrId}&usrId=${usrId}&notiType=${notiType}`)
  if (!response.data.success) {
    return Promise.reject(response.data.messages.mainMessage) 
}
  const result = response.data.jsonData;
  response.data.jsonData = [];
  return result;
}

// getNotificationBillsAndStatement
const getNotificationBillsAndStatement = async ({ wacAccId, wacWtrId, usrId, notiType }) => {
  const response = await axios.get(`${API_URL}/usr/fetchNotification.action?wacAccId=${wacAccId}&wacWtrId=${wacWtrId}&usrId=${usrId}&notiType=${notiType}`)
  if (!response.data.success) {
    return Promise.reject(response.data.messages.mainMessage) 
}
  const result = response.data.jsonData;
  response.data.jsonData = [];
  return result;
}


const notificationService = {
  getNotificationPayment,
  getNotificationOthers,
  getNotificationBillsAndStatement,
}
export default notificationService
import React, { useEffect, useState } from 'react'
import BowserServices from '../../img/colored/bowser_services.svg';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getWaterCompanies } from '../../features/wsp/wspSlice';
import { cancelBowserRequests, cleanPayAmount, getBowserRequests, reset, saveBowserRequest, setBowserPayment, setPayAmount } from '../../features/bowser/bowserSlice';
import { Button, CircularProgress, TextField } from '@mui/material';
import { formatMsToDate } from '../../utils';
import CardSkeleton from '../../components/CardSkeleton';
import DeleteModal from '../../components/DeleteModal';
import { useNavigate } from 'react-router-dom';


const Bowser = () => {
    const [vehicle, setVehicle] = useState([
        { id: 'PRIVATE', value: 'PRIVATE' },
        { id: 'COMPANY', value: 'COMPANY' },
    ])
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);
    const { waterCompanies, } = useSelector(state => state.wsp);
    const { isProcessing, bowserRequests, isLoading, isSuccess, isError, message } = useSelector(state => state.bowser)

    const [bowsers, setBowsers] = useState(1);
    const [bowserPrice, setBowserPrice] = useState(0);
    const [formData, setFormData] = useState({
        phone: '',
        location: '',
        date: '',
        wsp: '',
        ownership: '',
    });



    const { phone, location, wsp, date, ownership } = formData;

    const handleIncrement = () => {
        ;
        setBowsers(bowsers + 1);
        calcBill();
    }

    const handleDecrement = () => {
        if (bowsers == 1) {
            return;
        }
        setBowsers(bowsers - 1);
        calcBill();
    }

    const calcBill = () => {
        return (bowserPrice * bowsers);
    }

    const handleChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const _bowser = {
            sreqBowserCount: bowsers,
            sreqContactMobile: user.usrUsername,
            sreqContactName: `${user.usrFirstName} ${user.usrLastName}`,
            sreqStatus: 'PENDING',
            sreqWaterProviderId: wsp,
            sreqLocation: location,
            sreqType: "BOWSER",
            sreqUsrId: user.usrId,
            sreqDateExpected: date,
            sreqVehicleOwnership: ownership
            // sreqDestLatitude: -1.3403850000000001596589527252945117652416229248046875,
            // sreqDestLongitude: 36.69090166666666874561997246928513050079345703125,
        }

        if (!wsp) {
            toast.warning("Please select water service provider");
        } else {
            dispatch(saveBowserRequest(_bowser));
            if (!isProcessing) {
                setBowsers(1);
                setFormData((prevState) => ({
                    ...prevState,
                    phone: '',
                    location: '',
                    date: '',
                    wsp: '',
                    ownership: '',
                }))
            }
        }

    }

    const [money,setMoney] =  useState()
    const calcPrice = (b) => {
        console.log("b", b)
        console.log("sreqUnitCost", b.sreqUnitCost)
        console.log("sreqBowserCount", b.sreqBowserCount)
        console.log("sreqMileageUnitCost", b.sreqMileageUnitCost)
        return b.sreqUnitCost * b.sreqBowserCount + b.sreqMileageUnitCost
    }
    async function checkData() {
        const res = await dispatch(getWaterCompanies())

    }

    const handleSelectClick = () => {
        checkData();
    }

    const handlePayments=(money)=>{
        dispatch(setPayAmount(money));
        dispatch(setBowserPayment(true));
        navigate('/payments')
    }



    const [prodd, setProdd] = useState()
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };



    useEffect(() => {
        dispatch(getBowserRequests({
            wacWtrId: 1,
            usrId: user.usrId,
            sreqType: 'BOWSER'
        }));

        if (isSuccess && message) {
            toast.success(message);
        }

        dispatch(getWaterCompanies())
        dispatch(cleanPayAmount());


        return () => {
            dispatch(reset());
        }
    }, [toast, message, dispatch])



    return (
        <>
            <main role="main" class="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4 main-window">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-1">
                    <h4 class="page-intros">Bowser services</h4>
                </div>

                <div class="row main-deck">

                    <div class="col-12 col-md-5">
                        <div class="card">
                            <div class="card-body">


                                <h4 class="water-blue">Request Bowser</h4>
                                <div class="row">
                                </div>

                                <div class="the-form mt2">
                                    <form onSubmit={handleSubmit} class="mt2">

                                        <div class="form-group">
                                            <label for="phone">Contact phone number</label>
                                            <input type="text" name="phone"
                                                value={phone ? phone : user.usrUsername}
                                                onChange={handleChange} class="form-control"
                                                id="phone" aria-describedby="emailHelp"
                                                placeholder="Contact phone number" />
                                        </div>

                                        <div class="form-group" >
                                            <label for="exampleFormControlSelect1">Bowser ownership type</label>
                                            <div onFocus={handleSelectClick}>
                                                <select class="form-control" id="exampleFormControlSelect1" name='ownership' value={ownership}
                                                    onChange={handleChange}>
                                                    <option disabled value="">-- Select --</option>
                                                    {vehicle?.length && vehicle?.map(car => (
                                                        <option value={car.id} key={car.id}>{car.value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <label class="mt1">Number of bowsers </label>
                                        <div class="input-group mb-3 text-center ">
                                            <div class="input-group-prepend">
                                                <button type='button' onClick={handleDecrement} class="btn btn-silver btn-sm" id="minus-btn"><i class="fa fa-minus"></i></button>
                                            </div>
                                            <input type="number" id="qty_input" class="text-center form-control form-control-sm" value={bowsers} min="1" />
                                            <div class="input-group-prepend">
                                                <button type='button' onClick={handleIncrement} class="btn btn-silver btn-sm" id="plus-btn"><i class="fa fa-plus"></i></button>
                                            </div>
                                        </div>

                                        <div class="form-group mt2">
                                            <label for="location">Location</label>
                                            <input type="text" name="location"
                                                value={location}
                                                onChange={handleChange} class="form-control" id="location"
                                                placeholder="eg karen" required />
                                        </div>

                                        <div class="form-group mt2">
                                            <label for="date">Date expected</label>
                                            <input type="date" name="date"
                                                value={date}
                                                onChange={handleChange} class="form-control" id="date"
                                                placeholder="Date expected" required />
                                        </div>

                                        <div class="form-group" >
                                            <label for="exampleFormControlSelect1">Water service providers</label>
                                            <div onFocus={handleSelectClick}>
                                                <select class="form-control" id="exampleFormControlSelect1" name='wsp' value={wsp}
                                                    onChange={handleChange}>
                                                    <option disabled value="">-- Select --</option>
                                                    {waterCompanies?.length && waterCompanies?.map(waterCompany => (
                                                        <option value={waterCompany.wtrId} key={waterCompany.wtrId}>{waterCompany.wtrCompany}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>


                                        {!isProcessing ? (
                                            <button type="submit" class="mt1 button nav-link">Submit</button>
                                        ) : (
                                            <button style={{ 'textTransform': 'none' }} class="nav-link-light disabled" disabled>
                                                <CircularProgress size={15} color="inherit" />&nbsp; Submitting
                                            </button>
                                        )}


                                    </form>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-5">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">My Requests</h5>
                                <div class="row">
                                    {isLoading ? (<><CardSkeleton /></>) : (
                                        <>
                                            {bowserRequests?.length && bowserRequests?.map((bowser) => (
                                                <>
                                                    <div class="container-fluid mt1">
                                                        <div class="row for-readings pt1">

                                                            <div class="col pb1">
                                                                <div class="row">
                                                                    <div class="col">
                                                                        <div class="row">
                                                                            <div class="col-4 notf-img"><img src={BowserServices} alt="bowser_services" /></div>
                                                                            <div class="col-8 pull-right text-right r-date"> {formatMsToDate(bowser.sreqCreatedDate)}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="row mt0">
                                                                    <div class="col-6 notf">
                                                                        <p>{bowser.sreqLocation}</p>
                                                                        <p>{bowser.sreqBowserCount} {(bowser.sreqVehicleOwnership)} Bowsers</p>
                                                                        <p>KES {calcPrice(bowser)}</p>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="row">
                                                                            <div class="col text-right">
                                                                                <span style={{ color: '#b8b8b8;' }}><i class="icon ion-md-checkmark-circle"></i></span> {bowser.sreqStatus}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card-footer "> {/* notf-approved */}
                                                            <div class="row">
                                                                <div class="col-6 text-left"
                                                                    onClick={() => setProdd(bowser)}
                                                                >
                                                                    <a class="nav-link-shy"
                                                                        onClick={() => handleClickOpen()}
                                                                        href="#">Cancel <i class="icon ion-md-arrow"></i></a>
                                                                </div>
                                                                <div class="col-6 text-right"
                                                                
                                                                >
                                                                    <a class="nav-link-def" 
                                                               onClick={()=> handlePayments(calcPrice(bowser))} 
                                                                href="#">Pay now <i class="icon ion-md-arrow"></i></a></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </main>
            <DeleteModal open={open}  handleClose={handleClose} prodd={prodd}  sreqType ='BOWSER' usrId ={user.usrId}/>
        </>
    )
}

export default Bowser
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import axios from "axios"
import viewBillService from './viewBillService';



const initialState = {
    periods: [],
    monthBill: {},
    viewStatement: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    isErrorBill: false,
    isLoadingBill: false,
    isSuccessBill: false,
    isErrorViewStatement: false,
    isLoadingViewStatement: false,
    isSuccessViewStatement: false,
    message: '',

}


// getPeriods
export const getPeriods = createAsyncThunk('period/getPeriod', async (params, thunkApi) => {
    try {
        // const usrId = thunkApi.getState().auth.user.usrId;
        return await viewBillService.getPeriods(params);
    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})

// getMonthBill
export const getMonthBill = createAsyncThunk('period/getMonthBill', async (params, thunkApi) => {
    try {
        return await viewBillService.getMonthBill(params);
    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})

// getViewStatement
export const getViewStatement = createAsyncThunk('period/getViewStatement', async (params, thunkApi) => {
    try {
        return await viewBillService.getViewStatement(params);
    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})



export const viewBillSlice = createSlice({
    name: 'period',
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            // periods
            .addCase(getPeriods.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getPeriods.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.periods = action.payload

            })
            .addCase(getPeriods.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })


            // monthBill
            .addCase(
                getMonthBill.pending, (state) => {
                    state.isLoadingBill = true
                }
            )
            .addCase(
                getMonthBill.fulfilled, (state, action) => {
                    state.isLoadingBill = false
                    state.isSuccessBill = true
                    state.monthBill = action.payload[0]
                }
            )
            .addCase(
                getMonthBill.rejected, (state, action) => {
                    state.isLoadingBill = false
                    state.isErrorBill = true
                    state.message = action.payload
                }
            )

            // viewStatement
            .addCase(
                getViewStatement.pending, (state) => {
                    state.isLoadingViewStatement = true
                }
            )
            .addCase(
                getViewStatement.fulfilled, (state, action) => {
                    state.isLoadingViewStatement = false
                    state.isSuccessViewStatement = true
                    state.viewStatement = action.payload
                }
            )
            .addCase(
                getViewStatement.rejected, (state, action) => {
                    state.isLoadingViewStatement = false
                    state.isErrorViewStatement = true
                    state.message = action.payload
                }
            )

    }
})

export const { reset } = viewBillSlice.actions
export default viewBillSlice.reducer
import { Article, CloudUpload, Delete, Download, PictureAsPdf } from '@mui/icons-material';
import React, { useState } from 'react'
import Loader from './Loader';
import './attachment.css'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Button, Card, Divider, FormControl, FormLabel, IconButton, Typography } from '@mui/material';
 
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CloseIcon from '@mui/icons-material/Close';
import { cleanupStep1Details, postConnectionDoc } from '../../../features/apply_new_connection/applyNewConnectionSlice';
import { useDispatch, } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
export default function Fileform({setValue}) {
    const navigate = useNavigate()
    const [tenderDoc, setTenderDoc] = useState(null)
    const [image, setImage] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [productImage, setProductImage] = useState('')
    const [nationalid, setNationalId] = useState('');
    const [kraPin, setKraPin] = useState('');
    const [waterBill, setWaterBill] = useState('');
    const [titleDeed, setTitleDeed] = useState('');
    const [applicationForm, setApplicationForm] = useState('');

    const [docArr, setDocArr] = useState([])

    const handleNationalId = (e) => {
        console.log(e.target.files[0]);
        setNationalId(e.target.files[0]);
        setDocArr(current => [...current, e.target.files[0]]);
    }
  
    const handleKraPin = (e) => {
        console.log(e.target.files[0]);
        setKraPin(e.target.files[0]);
        setDocArr(current => [...current, e.target.files[0]]);
    }
    const handleWaterBill = (e) => {
        console.log(e.target.files[0]);
        setWaterBill(e.target.files[0]);
        setDocArr(current => [...current, e.target.files[0]]);
    }
    const handleTitleDeed = (e) => {
        console.log(e.target.files[0]);
        setTitleDeed(e.target.files[0]);
        setDocArr(current => [...current, e.target.files[0]]);
    }

    const handleApplicationForm = (e) => {
        console.log(e.target.files[0]);
        setApplicationForm(e.target.files[0]);
        setDocArr(current => [...current, e.target.files[0]]);
    }
  
    const handleProductImage = (e) => {
        console.log(e.target.files[0]);
        setProductImage(URL.createObjectURL(e.target.files[0]));
    }
    const removeSelectedImage = () => {
        setTenderDoc('');
    }
    const deleteImage = (e) => {
        setProductImage('')
    }
    const deleteNationalId = (e) => {
        setNationalId('')
    }
    const deleteKraPin = (e) => {
        setKraPin('')
    }
    const deleteWaterBill = (e) => {
        setWaterBill('')
    }
    const deleteTitleDeed = (e) => {
        setTitleDeed('')
    }
    const deleteApplicationForm = (e) => {
        setApplicationForm('')
    }


    const current = new Date();
    const year = current.getFullYear();
    const month = current.toLocaleString('default', { month: 'short' });
    const day = ("0" + current.getDate()).slice(-2);
    const date = `${month} ${day},${year}`;

    const notifySuccess = () => toast.success(' Successfully Uploaded!', {
        theme: "colored",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const notifyError = () => toast.error(' Not Uploaded!', {
        theme: "colored",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const dispatch = useDispatch()

    const saveAttachments = async (event) => {
        event.preventDefault()
        if (nationalid && kraPin && waterBill && titleDeed && applicationForm){
            for (let i = 0; i < docArr.length; i++) {
                const formData = new FormData();
                formData.append("file", docArr[i]);
                dispatch(postConnectionDoc(formData))
                toast.success("Application successfull");
                dispatch(cleanupStep1Details());
            }
        }
        if (!nationalid || !kraPin || !waterBill || !titleDeed || !applicationForm){
            toast.error("Please fill all fields")
        }
       
    }

    console.log("docArr", docArr)

    const handleBack=(e)=>{
        navigate('/connect/step1')
    }

    return (

        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                <Grid item md={6} xs={6} sx={{ mb: 1 }}>
                                <Typography
                                    variant={"h6"}
                                    sx={{ mb: 1, mx: 0, textTransform: 'capitalize',color:'var(--water-blue) !important' }}
                                >
                                    Download Application Form
                                </Typography>
                                <FormControl variant={"standard"}>
                                    <FormLabel htmlFor="my-input" variant={"subtitle"}>
                                        NOTE: Download application form, fill and upload it in the attachment section.
                                    </FormLabel>
                                    <Button
                                        // onClick={() => generateAttachment('REGISTRATION.doc')}
                                        sx={{
                                            textTransform: "capitalize",
                                            backgroundColor: "var(--water-blue) !important",
                                            color: "#fff",
                                            width:"400px"
                                        }}
                                        variant="outlined"
                                        color="inherit"
                                        startIcon={<Download />}
                                    >
                                        Download application form
                                    </Button>
                                </FormControl>
                            </Grid>
                </Grid>
                <Box>
                <Divider />
                </Box>
               
                <Grid item xs={12}>
                    <Typography variant="h6" sx={{color:'var(--water-blue) !important'}} >Upload attachments</Typography>
                </Grid>
                <Grid item md={4} xs={12}>

                    <Card sx={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '0.2rem',
                        borderWidth: '2px', width: '100%', height: { xs: '225px', md: '250px' }, cursor: 'pointer',
                        borderRadius: '0.5rem', boxShadow: '0 1.5px 3px 0 rgb(0 0 0 / 16%)'
                    }}>
                        {
                            isLoading ? (
                                <Loader />  
                                //     <Skeleton
                                //     className="my-0"
                                //     height={50}
                                //     inline={false}
                                //   />
                            ) : (
                                <>
                                    {
                                        !nationalid ? (
                                            <>
                                                <label style={{ width: '100%', height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                                    <div style={{ gap: '0.5rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                                        <CloudUpload className='cloudIcon' />
                                                        <p className='uploadImage'>
                                                        Copy of id
                                                        </p>
                                                    </div>

                                                    <input
                                                        type="file"
                                                        name='uploadimage'
                                                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                        onChange={handleNationalId}
                                                        style={{ width: '0px', height: '0px' }}
                                                    />

                                                </label>
                                            </>
                                        ) : (
                                            <>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between', height: '100%', width: '100%', border: 'none', borderRadius: '12px 10px 10px 12px', margin: '0px' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', borderRadius: '10px 0px 0px 10px' }}>
                                                        <span style={{ margin: "0px 15px" }}>
                                                            {
                                                                nationalid.type === "application/pdf" ? (
                                                                    <PictureAsPdf sx={{ fontSize: '70px', color: 'red' }} />
                                                                ) : (
                                                                    <Article sx={{ fontSize: '70px', color: '#1976d2' }} />
                                                                )
                                                            }

                                                        </span>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <div className='fw-700'>
                                                            {nationalid.name}
                                                        </div>

                                                        <div className='' style={{ fontWeight: 'lighter' }}>
                                                            uploaded on  {date}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <IconButton
                                                            aria-label="close"
                                                            onClick={deleteNationalId}
                                                            sx={{
                                                                color: (theme) => theme.palette.grey[500], mr: '6px'
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                    </Card>
                    {/* <Typography variant='paragraph' style={{ color: "#707070", fontSize: '15px' }}>Copy of id</Typography> */}
                </Grid>

                <Grid item md={4} xs={12}>
                    <Card sx={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '0.2rem',
                        borderWidth: '2px', width: '100%', height: { xs: '225px', md: '250px' }, cursor: 'pointer',
                        borderRadius: '0.5rem', boxShadow: '0 1.5px 3px 0 rgb(0 0 0 / 16%)'
                    }}>
                        {
                            isLoading ? (
                                <Loader />
                                //     <Skeleton
                                //     className="my-0"
                                //     height={50}
                                //     inline={false}
                                //   />
                            ) : (
                                <>
                                    {
                                        !kraPin ? (
                                            <>
                                                <label style={{ width: '100%', height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                                    <div style={{ gap: '0.5rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                                        <CloudUpload className='cloudIcon' />
                                                        <p className='uploadImage'>
                                                        KRA Pin
                                                        </p>
                                                    </div>

                                                    <input
                                                        type="file"
                                                        name='uploadimage'
                                                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                        onChange={handleKraPin}
                                                        style={{ width: '0px', height: '0px' }}
                                                    />

                                                </label>
                                            </>
                                        ) : (
                                            <>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between', height: '100%', width: '100%', border: 'none', borderRadius: '12px 10px 10px 12px', margin: '0px' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', borderRadius: '10px 0px 0px 10px' }}>
                                                        <span style={{ margin: "0px 15px" }}>
                                                            {
                                                                kraPin.type === "application/pdf" ? (
                                                                    <PictureAsPdf sx={{ fontSize: '70px', color: 'red' }} />
                                                                ) : (
                                                                    <Article sx={{ fontSize: '70px', color: '#1976d2' }} />
                                                                )
                                                            }

                                                        </span>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <div className='fw-700'>
                                                            {kraPin.name}
                                                        </div>

                                                        <div className='' style={{ fontWeight: 'lighter' }}>
                                                            uploaded on  {date}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <IconButton
                                                            aria-label="close"
                                                            onClick={deleteKraPin}
                                                            sx={{
                                                                color: (theme) => theme.palette.grey[500], mr: '6px'
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                    </Card>
                    {/* <Typography variant='paragraph' style={{ color: "#707070", fontSize: '15px' }}>KRA Pin</Typography> */}
                </Grid>


                <Grid item md={4} xs={12}>
                    <Card sx={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '0.2rem',
                        borderWidth: '2px', width: '100%', height: { xs: '225px', md: '250px' }, cursor: 'pointer',
                        borderRadius: '0.5rem', boxShadow: '0 1.5px 3px 0 rgb(0 0 0 / 16%)'
                    }}>
                        {
                            isLoading ? (
                                <Loader />
                                //     <Skeleton
                                //     className="my-0"
                                //     height={50}
                                //     inline={false}
                                //   />
                            ) : (
                                <>
                                    {
                                        !waterBill ? (
                                            <>
                                                <label style={{ width: '100%', height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                                    <div style={{ gap: '0.5rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                                        <CloudUpload className='cloudIcon' />
                                                        <p className='uploadImage'>
                                                        Copy of Water bill
                                                        </p>
                                                    </div>

                                                    <input
                                                        type="file"
                                                        name='uploadimage'
                                                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                        onChange={handleWaterBill}
                                                        style={{ width: '0px', height: '0px' }}
                                                    />

                                                </label>
                                            </>
                                        ) : (
                                            <>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between', height: '100%', width: '100%', border: 'none', borderRadius: '12px 10px 10px 12px', margin: '0px' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', borderRadius: '10px 0px 0px 10px' }}>
                                                        <span style={{ margin: "0px 15px" }}>
                                                            {
                                                                waterBill.type === "application/pdf" ? (
                                                                    <PictureAsPdf sx={{ fontSize: '70px', color: 'red' }} />
                                                                ) : (
                                                                    <Article sx={{ fontSize: '70px', color: '#1976d2' }} />
                                                                )
                                                            }

                                                        </span>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <div className='fw-700'>
                                                            {waterBill.name}
                                                        </div>

                                                        <div className='' style={{ fontWeight: 'lighter' }}>
                                                            uploaded on  {date}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <IconButton
                                                            aria-label="close"
                                                            onClick={deleteWaterBill}
                                                            sx={{
                                                                color: (theme) => theme.palette.grey[500], mr: '6px'
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                    </Card>
                    {/* <Typography variant='paragraph' style={{ color: "#707070", fontSize: '15px' }}>Copy of Water bill</Typography> */}
                </Grid>

                <Grid item md={4} xs={12}>
                    <Card sx={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '0.2rem',
                        borderWidth: '2px', width: '100%', height: { xs: '225px', md: '250px' }, cursor: 'pointer',
                        borderRadius: '0.5rem', boxShadow: '0 1.5px 3px 0 rgb(0 0 0 / 16%)'
                    }}>
                        {
                            isLoading ? (
                                <Loader />
                                //     <Skeleton
                                //     className="my-0"
                                //     height={50}
                                //     inline={false}
                                //   />
                            ) : (
                                <>
                                    {
                                        !titleDeed ? (
                                            <>
                                                <label style={{ width: '100%', height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                                    <div style={{ gap: '0.5rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                                        <CloudUpload className='cloudIcon' />
                                                        <p className='uploadImage'>
                                                        Copy of Title Deed
                                                        </p>
                                                    </div>

                                                    <input
                                                        type="file"
                                                        name='uploadimage'
                                                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                        onChange={handleTitleDeed}
                                                        style={{ width: '0px', height: '0px' }}
                                                    />

                                                </label>
                                            </>
                                        ) : (
                                            <>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between', height: '100%', width: '100%', border: 'none', borderRadius: '12px 10px 10px 12px', margin: '0px' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', borderRadius: '10px 0px 0px 10px' }}>
                                                        <span style={{ margin: "0px 15px" }}>
                                                            {
                                                                titleDeed.type === "application/pdf" ? (
                                                                    <PictureAsPdf sx={{ fontSize: '70px', color: 'red' }} />
                                                                ) : (
                                                                    <Article sx={{ fontSize: '70px', color: '#1976d2' }} />
                                                                )
                                                            }

                                                        </span>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <div className='fw-700'>
                                                            {titleDeed.name}
                                                        </div>

                                                        <div className='' style={{ fontWeight: 'lighter' }}>
                                                            uploaded on  {date}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <IconButton
                                                            aria-label="close"
                                                            onClick={deleteTitleDeed}
                                                            sx={{
                                                                color: (theme) => theme.palette.grey[500], mr: '6px'
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                    </Card>
                    {/* <Typography variant='paragraph' style={{ color: "#707070", fontSize: '15px' }}>Copy of Title Deed</Typography> */}
                </Grid>

                <Grid item md={4} xs={12}>
                    <Card sx={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '0.2rem',
                        borderWidth: '2px', width: '100%', height: { xs: '225px', md: '250px' }, cursor: 'pointer',
                        borderRadius: '0.5rem', boxShadow: '0 1.5px 3px 0 rgb(0 0 0 / 16%)'
                    }}>
                        {
                            isLoading ? (
                                <Loader />
                                //     <Skeleton
                                //     className="my-0"
                                //     height={50}
                                //     inline={false}
                                //   />
                            ) : (
                                <>
                                    {
                                        !applicationForm ? (
                                            <>
                                                <label style={{ width: '100%', height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                                    <div style={{ gap: '0.5rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                                        <CloudUpload className='cloudIcon' />
                                                        <p className='uploadImage'>
                                                        Copy of Application form
                                                        </p>
                                                    </div>

                                                    <input
                                                        type="file"
                                                        name='uploadimage'
                                                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                        onChange={handleApplicationForm}
                                                        style={{ width: '0px', height: '0px' }}
                                                    />

                                                </label>
                                            </>
                                        ) : (
                                            <>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between', height: '100%', width: '100%', border: 'none', borderRadius: '12px 10px 10px 12px', margin: '0px' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', borderRadius: '10px 0px 0px 10px' }}>
                                                        <span style={{ margin: "0px 15px" }}>
                                                            {
                                                                applicationForm.type === "application/pdf" ? (
                                                                    <PictureAsPdf sx={{ fontSize: '70px', color: 'red' }} />
                                                                ) : (
                                                                    <Article sx={{ fontSize: '70px', color: '#1976d2' }} />
                                                                )
                                                            }

                                                        </span>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <div className='fw-700'>
                                                            {applicationForm.name}
                                                        </div>

                                                        <div className='' style={{ fontWeight: 'lighter' }}>
                                                            uploaded on  {date}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <IconButton
                                                            aria-label="close"
                                                            onClick={deleteApplicationForm}
                                                            sx={{
                                                                color: (theme) => theme.palette.grey[500], mr: '6px'
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                    </Card>
                    {/* <Typography variant='paragraph' style={{ color: "#707070", fontSize: '15px' }}>Copy of Application form</Typography> */}
                </Grid>
            </Grid>
            <Box style={{ display: "flex", justifyContent: "flex-start", marginTop: '3rem' }}>
                <Button 
                variant='contained' 
                style={{ backgroundColor: "#B8B8B8", marginRight: '10px' }}
                // onClick={handleBack}
                onClick={() => setValue(0) } 
                >
                    previous
                    </Button>
                <Button
                 style={{backgroundColor:`var(--water-blue)` }}
                    variant="contained"
                    onClick={saveAttachments}
                >
                    Save and continue
                </Button>
            </Box>
        </div>

    )
}

import { Divider, Grid, Paper, Skeleton } from '@mui/material'
import React from 'react'

function CardSkeleton() {
  return (
    <Grid item xs={12}>
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} variant="outlined">
      <Grid container spacing={4}>
          <Grid item xs={9}>
            <Skeleton width={240} />
            <Skeleton width={140} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant='rectangular' width={90} height={28} />
          </Grid>
          <Grid item xs={12} style={{paddingTop: '10px'}}>
            <Divider/>
          </Grid>
          <Grid item xs={7} style={{paddingTop: '10px'}}>
            <Skeleton width={275} />
          </Grid>
      </Grid>
    </Paper>
</Grid>
  )
}

export default CardSkeleton
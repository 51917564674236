import React, {useEffect, useState} from 'react';
import {Box, Card, Typography, Button, TextField, MenuItem} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import SelectAccount from './SelectAccount';
import SelectWaterProvider from './SelectWaterProvider';
import Details from '../lipa-loan/details/Details';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getAccounts, reset} from "../../features/Account/accSlice";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const LipaLoan = () => {

    const [openInvoice, setOpenInvoice] = useState(false);

    const handleOpenInvoice = () => {
        console.log(acc);
        if (!acc) {
            toast.warning('Please select account number')
            return;
        }
        setOpenInvoice(true);

    };

    const handleCloseInvoice = () => {
        setOpenInvoice(false);
    };

    const [acc, setAcc] = React.useState('');
    const [selectedIndex, setSelectedIndex] = React.useState(0);


    const handleChange = (event, index) => {
        setAcc(event.target.value);
        setSelectedIndex(index)
    };

    const navigate = useNavigate()
    const dispatch = useDispatch()



    const { accounts,isLoading,isError,isSuccess,message } = useSelector((state) => state.acc)




    useEffect(() => {
        dispatch(getAccounts())

        if (isError) {
            console.log(message)
        }


    }, [navigate,isError, message, dispatch])



    return (
        <>
            <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Box container>
                            <Card sx={{my: 2, width: '100%', alignSelf: 'center'}} elevation={2}>
                                <Typography my={2} pl={1} variant={'subtitle1'} component={'h2'}>
                                    Please provide the following details
                                </Typography>
                                <Stack spacing={3} sx={{p: 1, m: 1}}>
                                    {/* <SelectWaterProvider/> */}
                                    <>
                                        {
                                            accounts.length && (
                                                <Box>
                                                    <TextField
                                                        id="outlined-select-account"
                                                        select
                                                        fullWidth
                                                        label="Account Number"
                                                        variant="outlined"
                                                        value={acc}
                                                        onChange={handleChange}
                                                        // helperText="Select Account Number"
                                                    >
                                                        {accounts.length && accounts.map((option, index) => (
                                                            <MenuItem key={option.wacId} value={option.wacId} sx={{p:1, m: 1}}>
                                                                {option.wacAccNumber} |  {option.wacCustName}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Box>
                                            )
                                        }
                                    </>

                                </Stack>
                                <Box textAlign='end' sx={{my: 2, pr: 1.5}}>
                                    {/*{*/}
                                    {/*    isLoading ? (*/}
                                    {/*        <LoadingButton*/}
                                    {/*            loading*/}
                                    {/*            loadingPosition="start"*/}
                                    {/*            variant="outlined"*/}
                                    {/*        >*/}
                                    {/*            Get Loan*/}
                                    {/*        </LoadingButton>*/}
                                    {/*    ) : (*/}
                                    {/*        <Button variant="contained" sx={{my: 3, display: 'flex', alignItems: 'end', justifyContent: 'end'}}>*/}
                                    {/*            Get Loan*/}
                                    {/*        </Button>*/}
                                    {/*    )*/}
                                    {/*}*/}
                                    <Button variant="contained" onClick={handleOpenInvoice}
                                            sx={{backgroundColor: `var(--water-blue)!important`}}>
                                        Get Loan
                                    </Button>
                                </Box>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>

            <Details open={openInvoice}  handleOpenInvoice={handleOpenInvoice} handleCloseInvoice={handleCloseInvoice} num={acc} />
        </>
    );
};

export default LipaLoan;

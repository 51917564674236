import React from 'react'
import { cancelBowserRequests, getBowserRequests } from '../features/bowser/bowserSlice'
import { useDispatch } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Error, ErrorOutline, PriorityHigh } from '@mui/icons-material';
import { getExhausterRequests } from '../features/exhauster/exhausterSlice';

function DeleteModal({open,handleClose,prodd,sreqType,usrId}) {
const dispatch =  useDispatch();
    const handleCancel = async() => { 
        const _bowser = {
            sreqId:prodd.sreqId,
            sreqBowserCount: prodd.sreqBowserCount,
            sreqContactMobile: prodd.sreqContactMobile,
            sreqContactName: prodd.sreqContactName,
            sreqWaterProviderId: prodd.sreqWaterProviderId,
            sreqLocation: prodd.sreqLocation,
            sreqType: prodd.sreqType,
            sreqUsrId: prodd.sreqUsrId,
            sreqDateExpected: prodd.sreqDateExpected,
            sreqVehicleOwnership:prodd.sreqVehicleOwnership
        }
        const res =  await dispatch(cancelBowserRequests(_bowser))
        
        if(sreqType === 'EXHAUSTER'){
            dispatch(getExhausterRequests({
                wacWtrId: 1,
                usrId: usrId,
                sreqType: sreqType
            }));
        }else{
            dispatch(getBowserRequests({
                wacWtrId: 1,
                usrId: usrId,
                sreqType: sreqType
            }));
        }
        handleClose();
        
    }
  return (
    <>
    <Dialog className="dialog-modal"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{display:'flex',alignItems:'center',gap:1.5}}>
        <span style={{display:'flex',alignItems:'center',justifyContent:'center',width:'30px',height:'30px',borderRadius:'50%',background:'#faad14'}}>
        <PriorityHigh sx={{color:'#fff'}}/>
        </span>
     
        {"Are you sure you want to cancel?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Once you cancel you can still request again
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" sx={{border:'1px solid #d9d9d9 !important',color:'inherit !important'}}>
            No</Button>
        <Button onClick={handleCancel} autoFocus variant="outlined" sx={{color:'#ff4d4f !important',border:'1px solid #ff4d4f !important'}}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  </>
  )
}

export default DeleteModal
import React, { useEffect, useState } from 'react'
import './auth.css'
import accs from '../../assets/img/accs.svg';
import google_signin from '../../assets/img/elements/google-signin.svg';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import AuthHeader from '../../components/header/AuthHeader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { formDataFromObject } from '../../utils';
import { register, reset } from '../../features/auth/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
 


const Signup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, isSuccess, isError, isProcessing, message} = useSelector(state => state.auth);

    const [formData, setFormData] = useState({
        usrFirstName: '',
        usrMobileNumber: '',
        usrEncryptedPassword: '',
        usrEncryptedPasswordAlt: '',
      });

    const { usrFirstName, usrMobileNumber, usrEncryptedPassword, usrEncryptedPasswordAlt } = formData;

    const handleChange = (e) => {
        setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value
        }))
    }

    const handleSignup = (e) => {
        e.preventDefault();
        if (usrEncryptedPassword.length < 4) {
            toast.error('Passwords too short');   
        } else if (usrEncryptedPassword != usrEncryptedPasswordAlt) {
            toast.error('Passwords do not match');   
        } else  {
            const user = formDataFromObject(formData);
            dispatch(register(user));
        }
    }

    useEffect(() => {
        if (isError) {
            toast.error(message)
        }
        if (isSuccess) {
            toast.success(message)
            navigate('/signin');
        }
        return () => {
            dispatch(reset());
        }
    }, [user, isSuccess, isError, message, navigate, dispatch])
    
    return (
        <>
            <AuthHeader />

            <div className="row no-gutters guttrs-xs">

                <div className="col-12 col-md-6 signup-height d-none d-sm-block">
                    <div className="row no-gutters">
                        <div className="col text-center pt3">

                        <img src={accs} alt="accounts" style={{ width: '67%'}}/>

                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 acs-height3">
                    <div className="row no-gutters">
                    <div className="col-md-8 offset-md-2">


                    
                    <div className="card card-nav-tabs card-plain">
                        <div className="card-header card-header-light">
                        <div className="nav-tabs-navigation">
                            <div className="nav-tabs-wrapper">
                            <ul className="nav nav-tabs tabs-place" data-tabs="tabs">
                                <li className="nav-item">
                                <Link className="nav-link-shy regstr1 active" to="/signup" data-toggle="tab">Register <i class="icon ion-md-arrow"></i></Link>
                                </li>
                                <li className="nav-item">
                                <Link className="nav-link-def regstr" to="/signin" data-toggle="tab">Login <i class="icon ion-md-arrow"></i></Link>
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div><div className="card-body">
                        <div className="tab-content text-left">

                            <div className="tab-pane active" id="home">
                            <h4 className="water-blue mt1">Register</h4>

                            <div className="row mt2">
                                <div className="col">
                                <form onSubmit={handleSignup}>
                                    <div className="form-group">
                                    <input type="text" name="usrFirstName" value={usrFirstName} onChange={handleChange} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Full Name" required/>
                                    </div>

                                    <div className="form-group">
                                    <input type="phone" name="usrMobileNumber" value={usrMobileNumber} onChange={handleChange} class="form-control" id="phone" placeholder="Phone number" required/>
                                    </div>

                                    <div className="form-group">
                                    <input type="password" name="usrEncryptedPassword" value={usrEncryptedPassword} onChange={handleChange} class="form-control" id="Password1" placeholder="Password" required/>
                                    </div>
                                    <div className="form-group">
                                    <input type="password" name="usrEncryptedPasswordAlt" value={usrEncryptedPasswordAlt} onChange={handleChange} class="form-control" id="Password2" placeholder="Confirm password" required/>
                                    </div>

                                    <div className="form-check mt2">
                                    <input type="checkbox" className="form-check-input" id="terms" />
                                    <label className="form-check-label" for="terms">I Accept the<a href="#">Terms & Conditions</a></label>
                                    </div>

                                    {!isProcessing ? (
                                        <button type="submit" className="mt2 nav-link-def col">Sign up</button>
                                    ) : (
                                        <button style={{'textTransform': 'none'}} className="mt2 nav-link-light disabled col" disabled>
                                            <CircularProgress size={15} color="inherit" />&nbsp; Signing up
                                        </button>
                                    )}

                                </form>
                                </div>
                            </div>

                            </div>

                            <div className="tab-pane" id="updates">
                            <h4 className="mt2">Welcome back</h4>
                    
                            <div className="row mt2">
                                <div className="col">
                                <form>
                                    <div className="form-group">
                                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Phone" />
                                    </div>

                                    <div className="form-group">
                                    <input type="password" className="form-control" id="Password1" placeholder="Password" />
                                    </div>
                                    <div className="forgot-pass text-right">
                                        <h6 style={{fontSize: '15px'}}>
                                            <Link to="/forgot-pass" className="tt-initial">
                                                Forgot password ?
                                            </Link>
                                        </h6>
                                    </div>

                                    <button type="submit" className="nav-link-def col">Sign in</button>

                                </form>
                                </div>
                            </div>
                            </div>

                        </div>
                        </div></div>
                    

                    </div>
                    </div>
                </div>

            </div>

            <Footer />

        </>
    )
}

export default Signup
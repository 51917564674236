import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAccounts } from "../../features/Account/accSlice";
import { Divider, List, ListItemButton, Menu, Stack, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuItem from "@mui/material/MenuItem";
import mpesa from "../../assets/img/elements/mpesa-logo.png";
import element from "../../assets/img/elements/element1.svg";
import creditCard from "../../assets/img/elements/credit-card-1.png";
import bank from "../../assets/img/elements/Bank-1.png";
import Mpesa from "../../components/mpesa/Mpesa";

const BuyTokens = () => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const open = Boolean(anchorEl);

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.auth)

    const { accounts, isLoading, isError, isSuccess, message } = useSelector((state) => state.acc)


    console.log('Info ---- ', accounts)
    console.log('User --- ', user.usrId)
    const id = user.usrId;

    useEffect(() => {
        if (isError) {
            console.log(message)
        }
        dispatch(getAccounts({
            usrId: id,
            //   usrId: 2022
        }))

    }, [navigate, isError, message, dispatch])


    return (
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4 main-window">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-1">
                <h4 className="page-intros">Buy water tokens</h4>
            </div>


            {isLoading ? (
                <p>Loading ...</p>
            ) : (
                <>

                    {accounts.length && (
                        <div className="row  main-deck">

                            <div className="col-12 col-md-6 smoke">
                                <h4 className="dash-intro water-blue">Account</h4>

                                <div className="wrapper-demo">
                                    <List
                                        component={Paper}
                                        aria-label="Device settings"
                                        sx={{ p: 2 }}
                                        onClick={handleClickListItem}
                                    >
                                        <ListItemButton
                                            id="lock-button"
                                            aria-haspopup="listbox"
                                            aria-controls="lock-menu"
                                            aria-label="when device is locked"
                                            aria-expanded={open ? 'true' : undefined}
                                        >
                                            <Stack
                                                direction="row"
                                                divider={<Divider orientation="vertical" flexItem />}
                                                spacing={2}
                                                primary="When device is locked"
                                                secondary={accounts[selectedIndex]}
                                            >
                                                <Typography>
                                                    {accounts[selectedIndex].wacAccNumber}
                                                </Typography>
                                                <Typography>
                                                    {accounts[selectedIndex].wacCustName}
                                                </Typography>
                                            </Stack>
                                            <ArrowDropDownIcon sx={{ ml: 2 }} />
                                        </ListItemButton>
                                    </List>
                                    <Menu
                                        id="lock-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        sx={{ m: 2, p: 3.5 }}
                                        MenuListProps={{
                                            'aria-labelledby': 'lock-button',
                                            role: 'listbox',
                                        }}
                                    >
                                        {accounts.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                selected={index === selectedIndex}
                                                sx={{ m: 2, p: 2, my: 1 }}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                            >
                                                <Stack
                                                    direction="row"
                                                    divider={<Divider orientation="vertical" flexItem />}
                                                    spacing={3}
                                                >
                                                    <Typography>
                                                        {option.wacAccNumber}
                                                    </Typography>
                                                    <Typography>
                                                        {option.wacCustName}
                                                    </Typography>
                                                </Stack>
                                            </MenuItem>
                                        ))}

                                    </Menu>
                                </div>

                                <hr />

                                <div className="mt1">
                                    <div className="row">
                                        <div className="col">Current active account</div>
                                        <div className="col"> {accounts[selectedIndex].wacAccNumber}  | {accounts[selectedIndex].wacCustName}</div>
                                    </div>
                                </div>

                                <h5>Summary</h5>

                                <div className="liners">
                                    <div className="row">
                                        <div className="col">Bill Amount:</div>
                                        <div className="col"> KES {accounts[selectedIndex].wacAccBal}</div>
                                    </div>
                                </div>
                                <div className="liners">
                                    <div className="row">
                                        <div className="col">Balance Due:</div>
                                        <div className="col"> 2/11/2022</div>
                                    </div>
                                </div>
                                <div className="liners">
                                    <div className="row">
                                        <div className="col">Meter Serial No.:</div>
                                        <div className="col"> {accounts[selectedIndex].wacCMtrNo}</div>
                                    </div>
                                </div>

                            </div>

                            <hr />

                            <div className="col-md-6 col-12 for-payments">
                                <h4 className="dash-intro">Select your mode of payment</h4>

                                <div className="row">


                                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home"
                                                role="tab" aria-controls="pills-home" aria-selected="true">
                                                <div className="main-methods the-lefts">
                                                    <div className="mpesa pay-shell">
                                                        <img src={mpesa} alt="" />
                                                    </div>
                                                </div>
                                            </a>
                                        </li>

                                        <li>
                                            <div className="method-space the-lefts">
                                                <img src={element} alt="" />
                                            </div>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile"
                                                role="tab" aria-controls="pills-profile" aria-selected="false">
                                                <div className="main-methods the-lefts">
                                                    <div className="mpesa pay-shell">
                                                        <img src={creditCard} alt="" />
                                                    </div>
                                                </div>
                                            </a>
                                        </li>

                                        <li>
                                            <div className="method-space the-lefts">
                                                <img src={element} alt="" />
                                            </div>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact"
                                                role="tab" aria-controls="pills-contact" aria-selected="false">
                                                <div className="main-methods the-lefts">
                                                    <div className="mpesa pay-shell">
                                                        <img src={bank} alt="" />
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>


                                </div>

                                <hr className="row" />

                                <div className="row">
                                    <div className="tab-content" id="pills-tabContent">

                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                                            aria-labelledby="pills-home-tab">
                                            <Mpesa account={accounts[selectedIndex].wacAccNumber} />
                                        </div>

                                        <div className="tab-pane fade" id="pills-profile" role="tabpanel"
                                            aria-labelledby="pills-profile-tab">
                                            <div className="the-form mt1">
                                                <p>Enter your card details below.</p>

                                                <form>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Name" />
                                                    </div>

                                                    <div className="form-group">
                                                        <input type="text" className="form-control"
                                                            id="exampleInputPassword1" placeholder="Card number" />
                                                    </div>

                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <input type="text" className="form-control"
                                                                        placeholder="MM" />
                                                                </div>
                                                                <div className="col-6">
                                                                    <input type="text" className="form-control"
                                                                        placeholder="YY" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col pull-right">
                                                            <input type="text" className="form-control"
                                                                placeholder="CVV CODE" />
                                                        </div>
                                                    </div>

                                                    <div className="form-check mt2">
                                                        <input type="checkbox" className="form-check-input" id="payment" />
                                                        <label className="form-check-label" htmlFor="payment">Always use
                                                            Mpesa selected mode of payment</label>
                                                    </div>

                                                    <div className="form-check mt1">
                                                        <input type="checkbox" className="form-check-input" id="autoBill" />
                                                        <label className="form-check-label" htmlFor="autoBill">Enable
                                                            Auto Billing</label>
                                                    </div>

                                                    <div className="form-check mt1">
                                                        <input type="checkbox" className="form-check-input" id="terms" />
                                                        <label className="form-check-label" htmlFor="terms">Accept <a
                                                            href="#">Terms & Conditions</a></label>
                                                    </div>

                                                    <button type="submit" className="mt2 button nav-link">Submit</button>

                                                </form>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="pills-contact" role="tabpanel"
                                            aria-labelledby="pills-contact-tab">
                                            <div className="the-form mt1">
                                                <p><b>NOTE: </b>Use the bank details below</p>

                                                <h6>1. Bank - KCB KIMATHI BRANCH</h6>
                                                <h6>2. Account 123 456</h6>

                                                <form className="mt2">
                                                    <div className="form-group">
                                                        <input type="number" className="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Amount you want to pay" />
                                                    </div>

                                                    <button type="submit" className="mt2 button nav-link">Submit
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </>
            )}


        </main>
    );
};

export default BuyTokens;


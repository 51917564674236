import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import axios from "axios"
import accountsService from './accountsService';



const initialState = {
    accounts: [],
   
    isError: false,
    isLoading: false,
    isProcessing: false,
    isSuccess: false,
    message: '',

}


// getAccounts
export const getAccounts = createAsyncThunk('period/getAccounts', async (_, thunkApi) => {
    try {
        const usrId = thunkApi.getState().auth.user.usrId;
        // console.log("thunkApi.getState().auth.", thunkApi.getState().auth.user.usrId);
        return await accountsService.getAccounts(usrId);
    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})

export const accountSlice = createSlice({
    name: 'period',
    initialState,
    reducers: {
        reset:(state)=>{
            state.isLoading = false
            state.isProcessing = false
            state.searchAccs = []
            state.isError = false
            state.isSuccess = false
            state.message = ''
         }
    },
    extraReducers: (builder) => {
        builder
            // periods
            .addCase(getAccounts.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAccounts.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.accounts = action.payload
                state.searchAccs = action.payload

            })
            .addCase(getAccounts.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.accounts = []
                state.searchAccs = []
            })

 
    }
})

export const { reset } = accountSlice.actions
export default accountSlice.reducer
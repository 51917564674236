import React, { useEffect, useState } from "react";
import acc_avatar from "../../assets/img/acc-avatar.svg";
import {
  Divider,
  List,
  ListItemButton,
  Menu,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAccounts } from "../../features/Account/accSlice";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getMeter, saveMeter } from "../../features/meter-reading/meterSlice";
import jisomee from "../../assets/apply_new_connection.svg";
import { formatMsToDate, getCurrentDate } from "../../utils";
import CardSkeleton from "../../components/CardSkeleton";
import TablePaginationActions from "../../components/pagination/TablePaginationActions";
const JisomeeMeter = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { accounts, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.acc
  );

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    console.log("index", accounts[index].wacAccNumber);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const saveMeterNumber = () => {
    toast.success("Sent !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    currentReading: '',
    location: ''
  })
  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }


  const { user } = useSelector((state) => state.auth);
  const saveMeterReading = async (event) => {
    event.preventDefault();

    const _formData = {
      mtrCurrentReading: formData?.currentReading,
      mtrUsrId: user.usrId,
      mtrWacAccNumber: accounts[selectedIndex]?.wacAccNumber,
      mtrWtrId: accounts[selectedIndex]?.wacWtrId,
      mtrAccId: accounts[selectedIndex]?.wacAccId,
      mtrDate: getCurrentDate(),
    };
    const res = await dispatch(saveMeter(_formData));
    if (res?.type == "applicationDetails/saveMeter/fulfilled") {
      setFormData({
        currentReading: '',
        location: ''
      })
    }
    dispatch(
      getMeter({
        mtrWacAccNumber: accounts[selectedIndex]?.wacAccNumber,
        // mtrWacAccNumber:{accounts[selectedIndex].wacCMtrNo},
      })
    );
  };

  useEffect(() => {
    dispatch(
      getAccounts({
        usrId: user.usrId,
        //  usrId: 2022
      })
    );
    if (isError) {
      console.log(message);
    }
  }, [navigate, isError, message, dispatch]);

  const { meter, isMeterLoading, isMeterError, isMeterSuccess } = useSelector(
    (state) => state.meter
  );
 
  useEffect(() => {
    if (isMeterError) {
      console.log(message);
    }
    dispatch(
      getMeter({
        mtrWacAccNumber: accounts ? accounts[selectedIndex]?.wacAccNumber : '',
        // mtrWacAccNumber:{accounts[selectedIndex].wacCMtrNo},
      })
    );
  }, [navigate, isMeterError, selectedIndex, message, dispatch]);

  /**
  * :: Initialize pagination - starts here
  */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - meter?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 3));
    setPage(0);
  };

  /** ::  Initialize pagination - ends here */

  return (
    <main
      role="main"
      className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4 main-window"
    >
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-1">
        <h4 className="page-intros">Jisomee meter</h4>
      </div>

      {meter == null ? (
        <p>No data</p>
      ) : (
        <>
          {isLoading ? (
            <Skeleton />
          ) : (
            <>
              {accounts?.length && (
                <div className="row main-deck">
                  <div className="col-12 col-md-6">
                    <div className="card">
                      <h4 className="dash-intro col water-blue">
                        Jisomee Meter
                      </h4>
                      <div className="card-body mt1">
                        <div className="wrapper-demo">
                          <List
                            component={Paper}
                            aria-label="Device settings"
                            sx={{ p: 2 }}
                            onClick={handleClickListItem}
                          >
                            <ListItemButton
                              id="lock-button"
                              aria-haspopup="listbox"
                              aria-controls="lock-menu"
                              aria-label="when device is locked"
                              aria-expanded={open ? "true" : undefined}
                            >
                              <Stack
                                direction="row"
                                divider={
                                  <Divider orientation="vertical" flexItem />
                                }
                                spacing={2}
                                primary="When device is locked"
                                secondary={accounts[selectedIndex]}
                              >
                                <Typography>
                                  {accounts[selectedIndex].wacAccNumber}
                                </Typography>
                                <Typography>
                                  {accounts[selectedIndex].wacCustName}
                                </Typography>
                              </Stack>
                              <ArrowDropDownIcon sx={{ ml: 2 }} />
                            </ListItemButton>
                          </List>
                          <Menu
                            id="lock-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            sx={{ m: 2, p: 3.5 }}
                            MenuListProps={{
                              "aria-labelledby": "lock-button",
                              role: "listbox",
                            }}
                          >
                            {accounts.map((option, index) => (
                              <MenuItem
                                key={option}
                                selected={index === selectedIndex}
                                sx={{ m: 2, p: 2, my: 1 }}
                                onClick={(event) =>
                                  handleMenuItemClick(event, index)
                                }
                              >
                                <Stack
                                  direction="row"
                                  divider={
                                    <Divider orientation="vertical" flexItem />
                                  }
                                  spacing={3}
                                >
                                  <Typography>{option.wacAccNumber}</Typography>
                                  <Typography>{option.wacCustName}</Typography>
                                </Stack>
                              </MenuItem>
                            ))}
                          </Menu>
                        </div>

                        <div className="mt1">
                          <div className="row">
                            <div className="col">Meter serial number:</div>
                            <div className="col">
                              {" "}
                              {accounts[selectedIndex].wacCMtrNo}
                            </div>
                          </div>
                        </div>

                        <hr />

                        <div className="the-form mt2">
                          <h6>Submit info of your meter below</h6>

                          <form className="mt2">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="currentReading"
                                aria-describedby="currentReading"
                                placeholder="Current meter reading"
                                onChange={handleChange}
                                value={formData.currentReading}
                                name="currentReading"
                              />
                            </div>

                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="location"
                                placeholder="Location"
                                onChange={handleChange}
                                value={formData.location}
                                name="location"
                              />
                            </div>

                            <button
                              type="submit"
                              className="mt2 button nav-link"
                              onClick={saveMeterReading}
                            >
                              Submit
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-12 col-md-6">
                    <div className="card">
                      <div class="card-body">
                        <h5 class="card-title">My Readings</h5>
                        <div class="row">
                          {isMeterLoading ? (
                            <>
                              <CardSkeleton />
                            </>
                          ) : (
                            <>
                              {meter?.length>0 &&
                                meter?.map((mtr) => (
                                  <>
                                    <div class="container-fluid mt1">
                                      <div class="row for-readings pt1">
                                        <div class="col pb1">
                                          <div class="row">
                                            <div class="col">
                                              <div class="row">
                                                <div class="col-4 notf-img">
                                                  <img
                                                    src={jisomee}
                                                    alt="jisomee_services"
                                                  />
                                                </div>
                                                <div class="col-8 pull-right text-right r-date">
                                                  {mtr?.mtrDate? formatMsToDate(mtr?.mtrDate) : ""}
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="row mt0">
                                            <div class="col-6 notf">
                                              <p>
                                                Reading: {mtr?.mtrCurrentReading}
                                              </p>
                                              <p>Remarks: {mtr?.mtrRemarks}</p>
                                            </div>
                                            <div class="col-6">
                                              <div class="row">
                                                <div class="col text-right">
                                                  <span
                                                    style={{
                                                      color: "#b8b8b8;",
                                                    }}
                                                  >
                                                    <i class="icon ion-md-checkmark-circle"></i>
                                                  </span>
                                                  {mtr?.mtrStatus}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="card-footer ">
                                        <div class="row">
                                          
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))?.reverse()}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-12 col-md-6">
                    <div className="card">
                      <div class="card-body">
                        <h5 class="card-title">My Readings</h5>
                        <div class="row">
                          <TableContainer>
                            <Table sx={{  }} aria-label="custom pagination table">
                              {isMeterLoading ? (
                                <CardSkeleton />
                              ) : (
                                <TableBody>
                                  {meter && (
                                    (rowsPerPage > 0 ? Array.from(meter)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : meter)?.map((mtr) => (
                                        <div class="container-fluid mt1">
                                          <div class="row for-readings pt1">
                                            <div class="col pb1">
                                              <div class="row">
                                                <div class="col">
                                                  <div class="row">
                                                    <div class="col-4 notf-img">
                                                      <img
                                                        src={jisomee}
                                                        alt="jisomee_services"
                                                      />
                                                    </div>
                                                    <div class="col-8 pull-right text-right r-date">
                                                      {mtr?.mtrDate ? formatMsToDate(mtr?.mtrDate) : ""}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="row mt0">
                                                <div class="col-6 notf">
                                                  <p>
                                                    Reading: {mtr?.mtrCurrentReading}
                                                  </p>
                                                  <p>Remarks: {mtr?.mtrRemarks}</p>
                                                </div>
                                                <div class="col-6">
                                                  <div class="row">
                                                    <div class="col text-right">
                                                      <span
                                                        style={{
                                                          color: "#b8b8b8;",
                                                        }}
                                                      >
                                                        <i class="icon ion-md-checkmark-circle"></i>
                                                      </span>
                                                      {mtr?.mtrStatus}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="card-footer ">
                                            <div class="row">

                                            </div>
                                          </div>
                                        </div>
                                    ))?.reverse()
                                  )}
                                  {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                      <TableCell colSpan={6} />
                                    </TableRow>
                                  )}
                                </TableBody>
                              )}

                              <TableFooter>
                                {
                                  Object.keys(meter)?.length > 3 ? (
                                    <TableRow>
                                      {isLoading ? (
                                        <Skeleton className="my-2" height={25} inline={false} />
                                      ) : (
                                        <TablePagination
                                          rowsPerPageOptions={[3, 5, 10, { label: 'All', value: -1 }]}
                                          colSpan={3}
                                          count={meter?.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onPageChange={handleChangePage}
                                          onRowsPerPageChange={handleChangeRowsPerPage}
                                          ActionsComponent={TablePaginationActions}
                                        />
                                      )}
                                    </TableRow>
                                  ) : (
                                    null
                                  )
                                }

                              </TableFooter>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </main>
  );
};

export default JisomeeMeter;

import axios from "axios";


const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;
 
const register = async(user)=>{
    const response = await axios.post(`${API_URL}/usr/postSignUpUser.action`, user, {
        headers: {'accept': 'application/json'}   
    })

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    
    if (response.data) {
        localStorage.setItem('user',JSON.stringify(response.data))
    }

    return response.data
}

const login = async(userData)=>{
    const response=await axios.post(`${API_URL}/j_spring_security_check`, userData, {
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}  
    })

    if (!response.data.success) {
        return Promise.reject(response.data.messages.mainMessage) 
    }
    
    if (response.data){
        localStorage.setItem('user',JSON.stringify(response.data))
    }
    return response.data.jsonData
}

const forgotPass = async(userId)=>{
    const response = await axios.post(`${API_URL}/usr/resetPassword.action`, userId, {
        headers: {'Accept': 'application/json'}
    })

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data
}

const authService={
   register,
   login,
   forgotPass,
}
export default authService

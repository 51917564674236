import React from 'react'
import { Outlet } from 'react-router-dom'
import AppBar from '../components/header/AppBar';
import Menu from '../components/menu/Menu';

const WithNav = () => {
  return (
    <>
      <AppBar />

      <div class="container-fluid">
        <div class="row">
          <Menu />
          <Outlet/>
        </div>
      </div>

      
    </>
  )
}

export default WithNav
import axios from "axios"

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;
 
const getCrmTickets = async({ wacAccId,wacWtrId})=>{
    const response = await axios.get(`${API_URL}/usr/fetchCmTicket.action?wacAccId=${wacAccId}&wacWtrId=${wacWtrId}`, {
        headers: {'Accept': 'application/json'}   
    })

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.jsonData
}
const getCmIssueCategory = async({ wacWtrId})=>{
    const response = await axios.get(`${API_URL}/usr/fetchCmIssueCategory.action?wacWtrId=${wacWtrId}`, {
        headers: {'Accept': 'application/json'}   
    })

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}


const saveCrmTicket = async (report) => {
    const response = await  axios.post(`${API_URL}/usr/postCrmTicket.action?wacWtrId=${report.wacWtrId}`, report, {
        headers: {'Accept': 'application/json'}
    })
    // return !response.data.success ? Promise.reject(response.data.messages.mainMessage)  : response.data.jsonData;
    return response.data;
}

const reportService = {
    getCrmTickets,
    saveCrmTicket,
    getCmIssueCategory
}
export default reportService

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleFailure } from '../utils';
import wspService from "./wspService";

const initialState = {
    waterCompanies: [],
    isSuccess: false,
    isError: false, 
    isLoading: false,
    message: '',
}

export const getWaterCompanies = createAsyncThunk(
    'water_companies',
    async(_, thunkAPI) => {
        try {
            return await wspService.getWaterCompanies()
        } catch (error) {
            const message = handleFailure(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const wspSlice = createSlice({
    name:'wsp',
    initialState,
    reducers:{
        reset:(state)=>{
           state.isLoading = false
           state.isError = false
           state.isSuccess = false
           state.message = ''
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getWaterCompanies.pending, (state)=>{
            state.isLoading = true
        })
        .addCase(getWaterCompanies.fulfilled, (state,action) =>{
            state.isLoading = false
            state.isSuccess = true
            state.waterCompanies = action.payload
        })
        .addCase(getWaterCompanies.rejected,(state,action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.waterCompanies = []
        })
    }
})

export const {reset} = wspSlice.actions
export default wspSlice.reducer
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import axios from "axios"
import viewBillService from './notificationService';



const initialState = {
    notificationPayment: [],
    notificationOthers: [],
    notificationBills: [],
     

    isError: false,
    isLoading: false,
    isSuccess: false,
 
    

    message: '',

}


// getNotificationPayment
export const getNotificationPayment = createAsyncThunk('notification/getNotificationPayment', async (params, thunkApi) => {
    try {
        return await viewBillService.getNotificationPayment(params);
    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})

// getNotificationOthers
export const getNotificationOthers = createAsyncThunk('notification/getNotificationOthers', async (others, thunkApi) => {
    try {
        return await viewBillService.getNotificationOthers(others);
    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})

// getNotificationBillsAndStatement
export const getNotificationBillsAndStatement = createAsyncThunk('notification/getNotificationBillsAndStatement', async (bills, thunkApi) => {
    try {
        return await viewBillService.getNotificationBillsAndStatement(bills);
    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})




export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            //notificationPayment
            .addCase(getNotificationPayment.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getNotificationPayment.fulfilled, (state, action) => {
                state.isLoading  = false
                state.isSuccess  = true
                state.notificationPayment   = action.payload

            })
            .addCase(getNotificationPayment.rejected, (state, action) => {
                state.isLoading  = false
                state.isError  = true
                state.message = action.payload
            })
            //notificationOthers
            .addCase(getNotificationOthers.pending, (state) => {
                state.isLoading  = true
            })
            .addCase(getNotificationOthers.fulfilled, (state, action) => {
                state.isLoading  = false
                state.isSuccess  = true
                state.notificationOthers   = action.payload

            })
            .addCase(getNotificationOthers.rejected, (state, action) => {
                state.isLoading  = false
                state.isError  = true
                state.message = action.payload
            })
            //NotificationBillsAndStatement
            .addCase(getNotificationBillsAndStatement.pending, (state) => {
                state.isLoading  = true
            })
            .addCase(getNotificationBillsAndStatement.fulfilled, (state, action) => {
                state.isLoading  = false
                state.isSuccess  = true
                state.notificationBills  = action.payload

            })
            .addCase(getNotificationBillsAndStatement.rejected, (state, action) => {
                state.isLoading = false
                state.isError  = true
                state.message = action.payload
            })


    }
})

export const { reset } = notificationSlice.actions
export default notificationSlice.reducer
import { Box, Card, Divider, Grid, MenuItem, Tab, Tabs, TextField } from '@mui/material';
import React, { useEffect } from 'react'
import './notificationTabs.css'
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PaymentsIcon from '@mui/icons-material/Payments';
import { PersonPinCircleOutlined } from '@mui/icons-material';
import NotificationPayments from '../../components/notifications/NotificationPayments';
import NotificationOthers from '../../components/notifications/NotificationOthers';
import NotificationBillsAndAStatement from '../../components/notifications/NotificationBillsAndAStatement';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAccounts } from '../../features/accounts/accountSlice';


const currencies = [

  {
    value: '0',
    label: '1',
  },
  {
    value: '1',
    label: '2',
  },
  {
    value: '2',
    label: '3',
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function NotificationTabs() {
  const [value, setValue] = React.useState(0);
  const [active, setActive] = React.useState(false)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { accounts, isLoading, isError, isSuccess, message } = useSelector((state) => state.acct)

  const { user } = useSelector(state => state.auth);
  console.log("user", user)
  useEffect(() => {
    if (isError) {
      console.log(message)
    }
    dispatch(getAccounts({
      // usrId: 1979,
      usrId:user.usrId
    }))
  }, [navigate, isError, message, dispatch])

  console.log("accounts", accounts)

  const [water, setWater] = useState('')

  console.log("water", water.water)



  return (
    <main role="main" class="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4 main-window">
          <h4 className="page-intros">Notifications</h4>
      <Box sx={{ width: '100%' }}>

{
  accounts == 0 ? (
    <>
    <p>You dont have water account</p>
    </>
  ):(
    <>
   
      {
          !water ? (
            <Grid container spacing={2} style={{ marginBottom: '15px' }}>
              <Grid item xs={12} md={6}>
              <Card style={{padding:'15px'}}>
                <p>Select account to view notifications</p>
                <TextField
                  id="outlined-select-account"
                  select
                  fullWidth
                  name="water"
                  label="Select Account"
                  variant="outlined"
                  onChange={(e) => {
                    setWater((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }))
                  }}
                >
                  {accounts.length && accounts.map((option) => (
                    <MenuItem key={option.wacAccId} value={option.wacAccId}>
                      {option.wacAccNumber}
                      <Divider orientation="vertical" sx={{ mx: 2 }} />
                      {option.wacCustName}
                    </MenuItem>
                  ))}
                </TextField>
                </Card>
              </Grid>
            </Grid>

          ) : (
            <>
              <Grid container spacing={2} style={{ marginBottom: '15px' }}>
                <Grid item xs={12} md={6}>
                <Card style={{padding:'15px'}}>
                <p>Select account to view notifications</p>
                  <TextField
                    id="outlined-select-account"
                    select
                    fullWidth
                    name="water"
                    label="Select Account"
                    variant="outlined"
                    onChange={(e) => {
                      setWater((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value,
                      }))
                    }}
                  >
                    {accounts.length && accounts.map((option) => (
                      <MenuItem key={option.wacAccId} value={option.wacAccId}>
                        {option.wacAccNumber}
                        <Divider orientation="vertical" sx={{ mx: 2 }} />
                        {option.wacCustName}
                      </MenuItem>
                    ))}
                  </TextField>
                  </Card>
                </Grid>
              </Grid>

              <Box sx={{ borderBottom: 1, borderColor: '#fff' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                  <Tab label={`Payments`}
                    sx={{
                      height: "auto", backgroundColor: value === 0 ? "#4156A6" : "#B8B8B8",
                      color: value === 0 ? "#FFFFFF !important"
                        : "#E4E4E4 !important",
                      margin: "8px", paddingY: "8px", borderRadius: "6px"
                    }}
                    {...a11yProps(0)} onClick={() => setActive(true)} index={0} />

                  <Tab label="Others " sx={{
                    backgroundColor: value === 1 ? "#369144" : "#B8B8B8",
                    color: value === 1 ? "#FFFFFF !important" : "#E4E4E4 !important",
                    margin: "8px", padding: "8px", borderRadius: "6px"
                  }}  {...a11yProps(1)} />


                  <Tab label="Bills & Statements" sx={{
                    backgroundColor: value === 2 ? "#024172" : "#B8B8B8",
                    color: value === 2 ? "#FFFFFF !important" : "#E4E4E4 !important",
                    margin: "8px", padding: "8px", borderRadius: "6px"
                  }}  {...a11yProps(1)} />

                </Tabs>
              </Box>

              <TabPanel value={value} index={0}>
                <NotificationPayments water={water} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <NotificationOthers water={water} />
              </TabPanel>

              <TabPanel value={value} index={2}>
                <NotificationBillsAndAStatement water={water} />
              </TabPanel>
            </>
          )
        }
    </>
  )
}
      



      </Box>
    </main>
  )
}
export default NotificationTabs
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import axios from "axios"
import invoiceService from './invoiceService';



const initialState = {
    invoice: {},
    isErrorInvoice: false,
    isLoadingInvoice: false,
    isSuccessInvoice: false,
     
    message: '',

}


// getInvoice
export const getInvoice = createAsyncThunk('invoice/getInvoice', async (params, thunkApi) => {
    try {
    
        return await invoiceService.getInvoice(params);
    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})
 



export const invoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            // invoice
            .addCase(getInvoice.pending, (state) => {
                state.isLoadingInvoice = true
            })
            .addCase(getInvoice.fulfilled, (state, action) => {
                state.isLoadingInvoice = false
                state.isSuccessInvoice = true
                state.invoice = action.payload[0]

            })
            .addCase(getInvoice.rejected, (state, action) => {
                state.isLoadingInvoice = false
                state.isErrorInvoice = true
                state.message = action.payload
            })

    }
})

export const { reset } = invoiceSlice.actions
export default invoiceSlice.reducer
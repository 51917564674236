import {
    Card,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    DialogContentText,
    IconButton,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import {
    Article,
    Circle,
    Today,
    InsertInvitation,
    Download,
    Assignment,
    ArrowForwardIosSharp,
    AssignmentOutlined
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import './details.css';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {useDispatch, useSelector} from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import LoanDialog from '../loan_dialog/LoanDialog';
import { useState } from 'react';
import { getAccounts, reset } from '../../../features/Account/accSlice';



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontWeight: 'bold' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
const Details = ({ open, handleOpenInvoice, handleCloseInvoice, monthBill, isLoadingBill, num }) => {

    const [openLoan, setOpenLoan] = useState(false);
    const [aLoans, setALoans] = useState([])

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { accounts, isLoading, isError, isSuccess, message } = useSelector((state) => state.acc)

    // const loans = accounts.filter((loan) => loan.wacId === num)
    const computeLoans = () =>{
        if (accounts.length) {
            return accounts.filter((loan) => loan.wacId === num)
        } else {
            return [];
        }
    }

    const handleOpenLoan = () => {
        setOpenLoan(true);
    };

    const handleCloseLoan = () => {
        setOpenLoan(false);
    };

    useEffect(() => {
        dispatch(getAccounts())

        if (isError) {
            console.log(message)
        }


    }, [navigate,isError, message, dispatch])


    return (
        <>
            <Dialog
                open={open}
                onClose={handleCloseInvoice}
                fullWidth={true}
                maxWidth="sm"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ borderRadius: 5 }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseInvoice}>
                    Details
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {
                        computeLoans().length && computeLoans().map((loanee)=>(
                            <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        
                                    <Grid item xs={12} md={12} style={{ display: 'flex', }}>
                                        <Grid item xs={6} md={6} style={{ fontWeight: '600' }}>
                                            Account Name:
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            {loanee.wacCustName}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={12} style={{ display: 'flex', }}>
                                        <Grid item xs={6} md={6} style={{ fontWeight: '600' }}>
                                            Account No.:
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            {loanee.wacAccNumber}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={12} style={{ display: 'flex',  }}>
                                        <Grid item xs={6} md={6} style={{ fontWeight: '600' }}>
                                            Bill Amount:
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            {loanee.wacAccBal}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={12} style={{ display: 'flex', }}>
                                        <Grid item xs={6} md={6} style={{ fontWeight: '600' }}>
                                            Phone:
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            0712345678
                                        </Grid>
                                    </Grid>

                                </Grid>
                            ))
                        
                    }

                </DialogContent>

                {/* <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <FormControlLabel control={<Checkbox />} label="Agree to    Terms & Conditions " />
                </div> */}
                
                <DialogActions>
                    <Button variant="contained"
                        sx={{
                            width: '100%',
                            border: 'none', textTransform: 'none', outline: '2px solid transparent', outlineOffset: '2px',
                            backgroundColor: `var(--water-blue)!important`,
                            color: 'white !important',
                            borderRadius: '0.5rem', fontWeight: '600', paddingLeft: '3rem', paddingRight: '3rem',
                            paddingTop: '0.5rem', paddingBottom: '0.5rem'
                        }}
                        onClick={handleOpenLoan}
                    >
                        Get Loan
                    </Button>
                </DialogActions>
            </Dialog>
            
            <LoanDialog open={openLoan} handleOpenLoan={handleOpenLoan} handleCloseLoan={handleCloseLoan}  amount={computeLoans().length && computeLoans()[0].wacAccBal} handleCloseInvoice={handleCloseInvoice} />
        </>
    )
}

export default Details
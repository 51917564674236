import axios from "axios"

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;
 
const getWaterCompanies = async()=>{
    const response = await axios.get(`${API_URL}/usr/fetchWaterCompanies.action`, {
        headers: {'accept': 'application/json'}   
    })

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.data
}

const wspService = {
    getWaterCompanies,
}
export default wspService

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleFailure } from "../utils";
import bowserService from "./bowserService";

const initialState = {
  bowserRequests: [],
  isLoading: false,
  isProcessing: false,
  isSuccess: false,
  isError: false,
  message: "",
  bowserExhaustAmount: "",
  bowserPayment: false,
  exhaustPayment: false,
  cleanPayAmount: false,
};

export const getBowserRequests = createAsyncThunk(
  "bowser_requests",
  async (params, thunkAPI) => {
    try {
      return await bowserService.getBowserRequests(params);
    } catch (error) {
      const message = handleFailure(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const saveBowserRequest = createAsyncThunk(
  "bowser_request/save",
  async (bowser, thunkAPI) => {
    try {
      return await bowserService.saveBowserRequest(bowser);
    } catch (error) {
      const message = handleFailure(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const cancelBowserRequests = createAsyncThunk(
  "bowser_request/cancel",
  async (bowser, thunkAPI) => {
    try {
      return await bowserService.cancelBowserRequests(bowser);
    } catch (error) {
      const message = handleFailure(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const bowserSlice = createSlice({
  name: "bowser",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isProcessing = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
      state.cleanPayAmount = false;
    },
    setPayAmount: (state, action) => {
      state.bowserExhaustAmount = action.payload;
    },
    setBowserPayment: (state, action) => {
      state.bowserPayment = action.payload;
    },
    setExhaustPayment: (state, action) => {
      state.exhaustPayment = action.payload;
    },
 

    cleanPayAmount: (state) => {
      state.bowserExhaustAmount = initialState.bowserExhaustAmount;
      state.bowserPayment = initialState.bowserPayment;
      state.exhaustPayment = initialState.exhaustPayment;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBowserRequests.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBowserRequests.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.bowserRequests = action.payload;
      })
      .addCase(getBowserRequests.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.bowserRequests = [];
      })
      .addCase(saveBowserRequest.pending, (state) => {
        state.isProcessing = true;
      })
      .addCase(saveBowserRequest.fulfilled, (state, action) => {
        state.isProcessing = false;
        state.isSuccess = true;
        state.bowserRequests = action.payload;
        state.message = "Bowser request submitted";
      })
      .addCase(saveBowserRequest.rejected, (state, action) => {
        state.isProcessing = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(cancelBowserRequests.pending, (state) => {
        state.isProcessing = true;
      })
      .addCase(cancelBowserRequests.fulfilled, (state, action) => {
        state.isProcessing = false;
        state.isSuccess = true;
        state.bowserRequests = action.payload;
        state.message = "Bowser request cancelled successfully";
      })
      .addCase(cancelBowserRequests.rejected, (state, action) => {
        state.isProcessing = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, setPayAmount, setBowserPayment,setExhaustPayment, cleanPayAmount } =
  bowserSlice.actions;
export default bowserSlice.reducer;

import React, { useEffect, useState } from 'react'
import AuthHeader from '../../components/header/AuthHeader'
import './auth.css'
import accs from '../../assets/img/accs.svg';
import google_signin from '../../assets/img/elements/google-signin.svg';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { forgotPass, reset } from '../../features/auth/authSlice';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formDataFromObject } from '../../utils';

const ForgotPass = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isProcessing, isError, isSuccess, message} = useSelector(state => state.auth);
    const [formData, setFormData] = useState({
        phone: ''
    }); 
    const { phone } = formData;

    const handleChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }
    const handleSubmit = () => {
        console.log("phone", phone);
        const userId = formDataFromObject(formData);
        dispatch(forgotPass(userId));
    }

    useEffect(() => {
        if (isError) {
            toast.error(message)
        }
        if (isSuccess) {
            toast.success(message)
            navigate('/signin');
        }
        return () => {
            dispatch(reset())
        }
    }, [isError, isSuccess, message, navigate])
  return (
    <>
        <AuthHeader />

        <div class="row no-gutters guttrs-xs">

                <div class="col-12 col-md-6 signup-height d-none d-sm-block">
                    <div class="row no-gutters">
                        <div class="col text-center pt3">

                        <img src={accs} alt="accounts" />

                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6 acs-height4">
                    <div class="row no-gutters">
                    <div class="col-md-8 offset-md-2">


                    
                    <div class="card card-nav-tabs card-plain">
                        <div class="card-header card-header-light">
                        <div class="nav-tabs-navigation">
                            <div class="nav-tabs-wrapper">
                            <ul class="nav nav-tabs tabs-place" data-tabs="tabs">
                                {/* <li class="nav-item">
                                    <Link class="nav-link-shy regstr1 active" to="/forgot-pass" data-toggle="tab">Forgot password <i class="icon ion-md-arrow"></i></Link>
                                </li> */}
                                {/* <li class="nav-item">
                                    <Link class="nav-link-def regstr" to="/signin" data-toggle="tab">Login <i class="icon ion-md-arrow"></i></Link>
                                </li> */}
                            </ul>
                            </div>
                            <div class="tab-content text-left">

                        <div class="mt2 tab-pane active" id="home">
                            <h4 class="mt2">Forgot Password</h4>
                            <p>Enter your phone number and we will send to you further instructions</p>

                            <div class="row mt2">
                                <div class="col">
                                <form>
                                    <div class="form-group">
                                    <input type="text" name="phone" value={phone} onChange={handleChange} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Phone number" required />
                                    </div>
                                
                                    {!isProcessing ? (
                                        <button type="button" onClick={handleSubmit} class="mt1 nav-link-def col">Submit</button>
                                    ) : (
                                        <button style={{'textTransform': 'none'}} class="mt1 nav-link-light disabled col" disabled>
                                            <CircularProgress size={15} color="inherit" />&nbsp; Submiting
                                        </button>
                                    )}

                                </form>
                                </div>
                            </div>
                            </div>


                        </div>
                        </div>
                        </div><div class="card-body">
                        
                        </div></div>
                    

                    </div>
                    </div>
                </div>
            </div>
        <Footer/>

    </>
  )
}

export default ForgotPass
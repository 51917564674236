import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import reportService from './reportService';

const initialState = {
    isLoading: false,
    isLoadingIssue: false,
    isProcessing: false,
    isSuccess: false,
    isError: false,
    message: '',
    cmIssues:[],
    crmIssues:[],
}

export const getCrmTickets = createAsyncThunk(
    'report_incidence',
    async(params, thunkAPI)=>{
        try {
            return await reportService.getCrmTickets(params)
        } catch(error) {
            const message = handleFailure(error)  
            return thunkAPI.rejectWithValue(message)
        }
});


export const saveCrmTicket = createAsyncThunk(
    'report_incidence/save',
    async(report ,thunkAPI)=>{
        try {
            return await reportService.saveCrmTicket(report)
        } catch(error) {
            const message = handleFailure(error)  
            return thunkAPI.rejectWithValue(message)
        }
});

export const getCmIssueCategory = createAsyncThunk(
    'getCmIssueCategory',
    async(params, thunkAPI)=>{
        try {
            return await reportService.getCmIssueCategory(params)
        } catch(error) {
            const message = handleFailure(error)  
            return thunkAPI.rejectWithValue(message)
        }
});



export const reportSlice = createSlice({
    name:'report',
    initialState,
    reducers:{
        reset:(state)=>{
           state.isLoading = false
           state.isProcessing = false
           state.isLoadingIssue =  false
           state.isError = false
           state.isSuccess = false
           state.message = ''
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getCrmTickets.pending, (state)=>{
            state.isLoading = true
        })
        .addCase(getCrmTickets.fulfilled, (state,action) =>{
            state.isLoading = false
            state.isSuccess = true
            state.crmIssues = action.payload
        })
        .addCase(getCrmTickets.rejected,(state,action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.crmIssues = []
        })
        .addCase(saveCrmTicket.pending, (state)=>{
            state.isProcessing = true
        })
        .addCase(saveCrmTicket.fulfilled, (state,action) =>{
            state.isProcessing = false
            state.isSuccess = true
            state.crmIssues.unshift(action.payload)
            state.message = 'Bowser request submitted'
        })
        .addCase(saveCrmTicket.rejected,(state,action)=>{
            state.isProcessing = false
            state.isError = true
            state.message = action.payload
        })

        .addCase(getCmIssueCategory.pending, (state)=>{
            state.isLoadingIssue = true
        })
        .addCase(getCmIssueCategory.fulfilled, (state,action) =>{
            state.isLoadingIssue = false
            state.isSuccess = true
            state.cmIssues = action.payload
        })
        .addCase(getCmIssueCategory.rejected,(state,action)=>{
            state.isLoadingIssue = false
            state.isError = true
            state.message = action.payload
            state.cmIssues = []
        })
    }

    
})

export const {reset} = reportSlice.actions
export default reportSlice.reducer
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Avatar, Button, Card, Chip, CircularProgress, InputAdornment, MenuItem } from '@mui/material';
import avatar from '../../img/avatar.jpg'
import acc from '../../img/acc-avatar.svg'
import './profile.css'
import { AccountCircle, Add, AddCircleOutlineOutlined, CalendarMonth, DeleteOutlineTwoTone, DeleteTwoTone } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import ViewBillDialog from '../../components/view_bill_dialog/ViewBillDialog';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { getMonthBill, getPeriods, getViewStatement, reset } from '../../features/view_bill/viewBillSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ViewStatementDialog from '../../components/view_statement_dialog/ViewStatementDialog';



const currencies = [

    {
        value: '0',
        label: '1',
    },
    {
        value: '1',
        label: '2',
    },
    {
        value: '2',
        label: '3',
    },
];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const months = [
    {
        value: '0',
        label: '0',
    },
    {
        value: '1',
        label: '1',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3',
    },
];

function Profile() {
    const formatMsforHumans = (timestamp) => {
        if (!timestamp) return;
        let date = new Date(timestamp);

        let year = date.getFullYear();
        let month = ("0" + (date.getMonth() + 1)).slice(-2);
        let day = ("0" + date.getDate()).slice(-2);

        return `${day}/${month}/${year}`;
    }

    const handleClick = (e) => {
    }
    const [value, setValue] = React.useState(null);
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [month, setMonth] = useState('');
    const [startDates, setStartDates] = useState(null);
    const [endDates, setEndDates] = useState(null);
    const handleClickCalendar = (event) => {
        setOpen(true);
        setAnchorEl(event.currentTarget);
    };


    const [openBill, setOpenBill] = useState(false);
    const handleOpenBill = () => {
        handleViewBill();
        setOpenBill(true);

    };

    const handleCloseBill = () => {
        setOpenBill(false);
    };

    const [openStatement, setOpenStatement] = useState(false);
    const handleOpenStatement = () => {
        handleViewStatement();
        setOpenStatement(true);
    };

    const handleCloseStatement = () => {
        setOpenStatement(false);
    };

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { periods, viewStatement, monthBill,
        isLoading, isLoadingBill, isLoadingViewStatement,
        isError, isErrorBill, isErrorViewStatement,
        isSuccess, isSuccessBill, isSuccessViewStatement,
        message } = useSelector((state) => state.periods)
    useEffect(() => {
        if (isError) {
            console.log(message)
        }
        dispatch(getPeriods({
            wacWtrId: 1,
        }))
    }, [navigate, isError, message, dispatch])

    const handleViewBill = () => {
        dispatch(getMonthBill({
            // WaterCompanies /service providers
            wacWtrId: 1,
            // account
            wacAccId: 50840,
            wacPdId: month.month,
        }))
    }

    const handleViewStatement = () => {
        dispatch(getViewStatement({
            wacAccId: 50840,
            wacWtrId: 1,
            startDate: formatMsforHumans(startDates),
            endDate: formatMsforHumans(endDates),
            wacEmail: ""
        }))
    }

    const [account, setAccount] = useState('')
    console.log("account", account)

    const { user } = useSelector((state) => state.auth)
    console.log("user", user)
    return (
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4 main-window">
            <h4 className="page-intros">Profile</h4>
            <Grid container spacing={2}  >
                <Grid item xs={12} md={5}>
                    <Card style={{ padding: '1rem', marginBottom: '2.5rem' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Chip label="Edit" variant="outlined" onClick={handleClick} style={{ color: '#007bff' }} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                            {
                                user ? (
                                    <>
                                        <Grid item xs={4} md={3}>
                                            <Avatar {...stringAvatar(`${user.usrFirstName} ${user.usrLastName}`)} />
                                        </Grid>
                                        <Grid item xs={8} md={8}>
                                            <h5>{user.usrFirstName} {user.usrLastName}</h5>
                                            <div>Phone: {user.usrMobileNumber}</div>
                                            <div>Email: {user.usrEmail}</div>

                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={4} md={3}>
                                            <div>
                                                <img
                                                    style={{ height: '100px', width: '100px', borderRadius: '50%', overflow: 'hidden', textAlign: 'center', margin: 'auto' }}
                                                    src={avatar}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={8} md={5}>
                                            <h5>Jane Doe</h5>
                                            <div>Phone: 0798 423 232</div>
                                            <div>Email: janedoe@gmail.com</div>

                                        </Grid>
                                    </>
                                )
                            }
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </main>
    )
}

export default Profile

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
            fontSize: '40px',
            width: 80,
            height: 80,
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

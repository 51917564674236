import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getAccounts} from "../../features/Account/accSlice";
import {getMpesa} from "../../features/mpesa/mpesaSlice";
import {toast} from "react-toastify";

const  Mpesa = ({account}) => {
    console.log('Account --- ', account)
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        phoneNumber: '',
        amount: '',
    });

    const {phoneNumber,amount} = formData;

    const { user } = useSelector((state) => state.auth)

    const { mpesa, isError, isLoading, isSucess, message } = useSelector((state) => state.mpesa);

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const notify = () => toast.success(' Please check your phone!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
    const saveMpesa = async(e) => {
        e.preventDefault()

        dispatch(getMpesa({
            phone:formData.phoneNumber || user.usrMobileNumber,
            amount:formData.amount,
            accountref:account,
        }))

        if (isSucess) {
            notify()
        }
    }

    useEffect(() => {
        if (isError) {
            console.log(message)
        }
        // dispatch(getMpesa(data));

    }, [isError, message, dispatch])

    console.log('User Mpesa', user)

    return (

        <div className="the-form mt1">
            <p>Enter phone number you will receive a prompt on your phone to <br/>complete
                payment.</p>

            <form>

                <div className="form-group">
                    <input
                        type="phone"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Phone number"
                        name='phoneNumber'
                        value={phoneNumber ? phoneNumber : user.usrMobileNumber}
                        onChange={onChange}
                    />
                </div>

                <div className="form-group">
                    <input
                        type="number"
                        className="form-control"
                        id="exampleInputEmail1"
                           aria-describedby="emailHelp"
                        placeholder="Amount"
                        name='amount'
                        onChange={onChange}
                    />
                </div>

                <div className="form-check mt1">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                    <label className="form-check-label" htmlFor="exampleCheck1">Always
                        use Mpesa selected <br/> mode of payment</label>
                </div>

                <button type="submit" className="mt2 button nav-link" onClick={saveMpesa}>Submit</button>

            </form>
        </div>
    );
}

export default Mpesa;
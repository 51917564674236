import axios from "axios"

const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;

// ${API_URL}/usr
// getInvoice
const getInvoice = async ({ wacWtrId,invoCode }) => {
  const response = await axios.get(`${API_URL}/usr/searchInvoice.action?wacWtrId=${wacWtrId}&invoCode=INV001837493`)
  if (!response.data.success) {
    return Promise.reject(response.data.messages.mainMessage) 
}
  const result = response.data.jsonData;
  response.data.jsonData = [];
  return result;
}

 



const invoiceService = {
  getInvoice,
}
export default invoiceService
import axios from "axios"

const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;

// ${API_URL}/usr
// getLoanee
const getLoanee = async ({ lnUsrId,wacWtrId}) => {
  const response = await axios.get(`${API_URL}/usr/fetchNBKLoan.action?lnUsrId=${lnUsrId}&wacWtrId=${wacWtrId}`)
  if (!response.data.success) {
    return Promise.reject(response.data.messages.mainMessage) 
}

  const result = response.data.jsonData;
  response.data.jsonData = [];
  return result;
}

// postApplyLoanNow
const postApplyLoanNow = async (_formData) => {
  console.log("_formData",_formData.lnWtrId)

    const response = await axios.post(`${API_URL}/usr/postNBKLoan.action?wacWtrId=${_formData.lnWtrId}`,_formData)
    if (response.status != 200) {
      return Promise.reject("Error occured while processing. Try again") 
  }
    const result = response.data;
  //   response.data.jsonData = [];
    return result;
  }

// getLoanee
const getLoaneeSchedule = async ({ amount,months,usrId,wacWtrId}) => {
  const response = await axios.get(`${API_URL}/usr/fetchLoanSchedule.action?amount=${amount}&months=${months}&usrId=${usrId}&wacWtrId=${wacWtrId}`)
  if (!response.data.success) {
    return Promise.reject(response.data.message)
  }
  const result = response.data.jsonData;
  response.data.jsonData = [];
  return result;
}


const loaneeService = {
  getLoanee,
  postApplyLoanNow,
  getLoaneeSchedule,
}
export default loaneeService

import {
    Card,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    DialogContentText,
    IconButton,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import {
    Article,
    Circle,
    Today,
    InsertInvitation,
    Download,
    Assignment,
    ArrowForwardIosSharp,
    AssignmentOutlined
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import './invoice.css';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useDispatch,useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import Loan from '../loan/Loan';
import { useState } from 'react';
import { reset } from '../../../features/invoice/invoiceSlice';
import { getLoanee } from '../../../features/loanee/loaneeSlice';
 
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontWeight: 'bold' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
const Invoice = ({ open, handleOpenInvoice, handleCloseInvoice, invoice, isLoadingInvoice,lnWtrId }) => {
    console.log("receied invoice",invoice)
    console.log("receied lnWtrId",lnWtrId)
    const dispatch = useDispatch()
    useEffect(() => {
    }, [reset, invoice])

    const { user } = useSelector(state => state.auth);
    console.log("user", user)
  
    const { loanee, isLoadingLoanee, isErrorLoanee, isSuccessLoanee,message } = useSelector((state) => state.loanee)

    const [openLoan, setOpenLoan] = useState(false)
    const handleOpenLoan = () => {
      
        setOpenLoan(true);
    };

    const handleCloseLoan = () => {
        setOpenLoan(false);
    };


    return (
        <>
            <Dialog
                open={open}
                onClose={handleCloseInvoice}
                fullWidth={true}
                maxWidth="sm"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ borderRadius: 5 }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseInvoice}>
                    INVOICE : {invoice.invoCode}
                </BootstrapDialogTitle>

                {
                    !isLoadingInvoice && (
<DialogContent dividers>
                    <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                            <Grid item xs={6} md={6} style={{ fontWeight: '600' }} style={{ fontWeight: '600' }}>
                                Name
                            </Grid>
                            <Grid item xs={6} md={6}>
                                {invoice.custName}
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                            <Grid item xs={6} md={6} style={{ fontWeight: '600' }} style={{ fontWeight: '600' }}>
                                Amount
                            </Grid>
                            <Grid item xs={6} md={6}>
                            {invoice.invoAmount}
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                            <Grid item xs={6} md={6} style={{ fontWeight: '600' }}>
                                Acc No
                            </Grid>
                            <Grid item xs={6} md={6}>
                                 {invoice.accNumber}
                            </Grid>
                        </Grid>

                    </Grid>

                </DialogContent>
                    )}

                    {
                       isLoadingInvoice && (
                        <Box sx={{p:1.5}}>
                            <Skeleton   
                            style={{ marginTop: '.75rem'}}
                            count={3}
                            height={25}
                            inline={false}
                         />
                        </Box>
                       ) 
                    }
                

                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <FormControlLabel control={<Checkbox />} label="Agree to    Terms & Conditions " />
                </div>
                <DialogActions>
                    <Button variant="contained"
                        // disabled={!startDates || !endDates ? true : false}
                        sx={{
                            width: '100%',
                            border: 'none', textTransform: 'none', outline: '2px solid transparent', outlineOffset: '2px',
                            backgroundColor: `var(--water-blue)!important`,
                            color: 'white !important',
                            borderRadius: '0.5rem', fontWeight: '600', paddingLeft: '3rem', paddingRight: '3rem',
                            paddingTop: '0.5rem', paddingBottom: '0.5rem'
                        }}
                        onClick={handleOpenLoan}
                    >
                        Get Loan
                    </Button>
                </DialogActions>
            </Dialog>
            <Loan open={openLoan} handleOpenLoan={handleOpenLoan} handleCloseLoan={handleCloseLoan} invoice={invoice.invoAmount} lnWtrId={lnWtrId} handleCloseInvoice={handleCloseInvoice}/>
        </>
    )
}

export default Invoice
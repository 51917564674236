import React from 'react';
import Logo from '../../assets/img/logo-white.svg'

const Footer = () => {
    return (
        <>
            <footer className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4  col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                {/* <p><img className="img-fluid" src={Logo} alt="" /></p> */}
                                <p className='majikonnect'>MajiKonnect 2.0</p>
                                <p>© 2022 MajiKonnect, All Rights Reserved</p>
                                <p><a href="#">Follow & talk to us on Twitter</a></p>
                            </div>
                        </div>

                        <div className="col-lg-4  col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <p><a href="#">About Us</a></p>
                                <p><a href="#">Terms & Conditions</a></p>
                                <p><a href="#">Privacy Policy</a></p>
                                <p><a href="#">Help</a></p>
                            </div>
                        </div>

                        <div className="col-lg-4  col-md-6 col-sm-6">
                            <div className="single-footer-widget mail-chimp">

                                <p>Fair Acres rd<br />
                                    Hekima Close, Building 48<br />
                                    Karen, Nairobi <br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import MpesaService from "./mpesaService";



const initialState = {
    mpesa: [],
    isMeterError: false,
    isMeterLoading: false,
    isMeterSuccess: false,
    message: '',

}


// getAccounts
export const getMpesa = createAsyncThunk('mpesa/getMpesa', async (params, thunkApi) => {
    try {

        return await MpesaService.getSTK(params);
    } catch (error) {
        const message = handleFailure(error)
        return thunkApi.rejectWithValue(message)
    }
})


export const MpesaSlice = createSlice({
    name: 'mpesa',
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            // mpesa
            .addCase(getMpesa.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMpesa.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.mpesa = action.payload

            })
            .addCase(getMpesa.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }
})

export const { reset } = MpesaSlice.actions
export default MpesaSlice.reducer
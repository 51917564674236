import axios from "axios"

const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;


// getAccounts
const getAccounts = async (usrId) => {
  const response = await axios.get(`${API_URL}/usr/fetchWaterAccounts.action?usrId=${usrId}`)
  if (!response.data.success) {
    return Promise.reject(response.data.messages.mainMessage) 
}
  const result = response.data.jsonData;
  response.data.jsonData = [];
  return result;
}

const accountsService = {
  getAccounts,
}
export default accountsService
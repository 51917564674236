import axios from "axios"

const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;


// getPeriods
const getPeriods = async ({ wacWtrId }) => {
  const response = await axios.get(`${API_URL}/usr/fetchPeriods.action?wacWtrId=${wacWtrId}`)
  if (!response.data.success) {
    return Promise.reject(response.data.messages.mainMessage) 
}
  const result = response.data.jsonData;
  response.data.jsonData = [];
  return result;
}

// getMonthBill
const getMonthBill = async ({ wacWtrId, wacAccId, wacPdId }) => {
  const response = await axios.get(`${API_URL}/usr/fetchBillPerPeriod.action?wacWtrId=${wacWtrId}&wacAccId=${wacAccId}&wacPdId=${wacPdId}`)
  if (!response.data.success) {
    return Promise.reject(response.data.messages.mainMessage) 
}
  const result = response.data.jsonData;
  response.data.jsonData = [];
  return result;
}

// getViewStatement
const getViewStatement = async ({ wacAccId, wacWtrId, startDate, endDate, wacEmail }) => {
  console.log("startDate", startDate)
  console.log("endDate", endDate)
  console.log("wacEmail", wacEmail)
  const response = await axios.get(`${API_URL}/usr/fetchAccountStatement.action?wacAccId=${wacAccId}&wacWtrId=${wacWtrId}&startDate=${startDate}&endDate=${endDate}&wacEmail=${wacEmail}`)
  if (!response.data.success) {
    return Promise.reject(response.data.messages.mainMessage) 
}
  console.log("viewStatement", response.data.jsonData)
  const result = response.data.jsonData;
  response.data.jsonData = [];
  return result;
}



const viewBillService = {
  getPeriods,
  getMonthBill,
  getViewStatement,
}
export default viewBillService